import React from 'react'
import { Icons } from '../../../../icons'

const ChecklistTab = () => {
  return (
    <div className="">
    {/* Table */}
    <div className="list-table-head">
      <table className="list-table">
        <thead className=''>
          <tr className="list-table-container">
            <th className="list-th">Activity</th>
            <th className="list-th">Checklist</th>
            <th className="list-th">Approved</th>
            <th className="list-th">Not Approved</th>
            <th className="list-th">File</th>
          </tr>
        </thead>
        <tbody>
          {/* Example Row */}
          <tr className="list-tr">
     
            <td className="list-th">-</td>
            <td className="list-th">-</td>
            <td className="list-th">-</td>

            <td className="list-th">-</td>
            <td className="list-th"><Icons.attechfile /></td>
          </tr>
        </tbody>
      </table>
    </div>
      {/* Send Button */}
      <div className=' flex justify-end mt-4'>
      <h1 className='send-btn'>Send as message</h1>
      </div>
  </div>
  )
}

export default ChecklistTab