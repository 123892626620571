import React from 'react'
import { Icons } from '../../../icons'
import Safety from './Safety'

const RiskAssessment = () => {
  return (
    <div className="">
    <div className="overflow-x-auto">
        <Safety />
        <table className="table-in-cashflow">
            <thead>
                <tr className="bg-[#78222E] text-white">
                    <th className="list-th-common ">Attach File</th>
                    <th className="list-th-common ">Checked by</th>
                    <th className="list-th-common ">Approved By</th>
                       </tr>
            </thead>
            <tbody>
                {[...Array(4)].map((_, rowIndex) => (
                    <tr key={rowIndex}>
                        {[...Array(3)].map((_, colIndex) => (
                            <td
                                key={colIndex}
                                className="td-cash "
                            >
                         {colIndex === 0 &&(
                          <Icons.attechfile />
                         )}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
</div>  
 )
}

export default RiskAssessment