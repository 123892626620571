import React, { useState } from 'react';
import { Icons } from '../../../../icons';
import AccessLog from './AccessLog';

const DrawingTab = () => {


  return (
    <>
    <div className="">
      {/* Table */}
      <div className="list-table-head overflow-x-auto">
        <table className="list-table w-full">
     
            <tr className="list-table-container">
              <th className="list-th">Drawing No.</th>
              <th className="list-th">Drawing Name</th>
              <th className="list-th">Revision</th>
              <th className="list-th">Status</th>
              <th className="list-th">Folder</th>
              <th className="list-th">Access</th>
              <th className="list-th">Transmittal</th>
              <th className="list-th">Upload</th>
            </tr>
        
          <tbody>
            {/* Example Row */}
            <tr className="list-tr">
              <td className="list-th">123456</td>
              <td className="list-th">lorem ipsum</td>
              <td className="list-th">123456</td>
              <td className="list-th"> <select className="select-div">
                <option>Select</option>
                <option>Tender</option>
                <option>Approval</option>
                <option>Option 3</option>
              </select></td>
              <td className="list-th"> <select className="select-div">
                <option >Select</option>
                <option>Tender</option>
                <option>Approval</option>
                <option>Option 3</option>
              </select></td>
              <td className="list-th">Global Company</td>
              <td className="list-th"><span className='send-btn'>Send</span></td>
              <td className="list-th"><span className='upload-btn'>Upload</span></td>

            </tr>
          </tbody>
        </table>
      </div>
          {/* Pagination */}
          <div className="pagi-container">
        {/* Previous Button */}
        <button
          className="pagi-prev-btn"
          aria-label="Previous"
        >
          <Icons.sliderarrowback />
        </button>

        {/* Pagination Dots */}
        <div className="pagi-dots">
          {[1, 2,'...',60].map((item) => (
            <button
              key={item}
              className={`sm:px-4 px-2 sm:py-2 ${item === 2 ? "bg-[#78222E] text-white" : "bg-gray-200 text-gray-600"
                }`}
            >
              {item}
            </button>
          ))}
        </div>

        {/* Next Button */}
        <button
          className="pagi-next-btn"
          aria-label="Next"
        >
          <Icons.sliderarrowgo />
        </button>
      </div>
    </div>
    <div className='mt-4' >
      <AccessLog />
      </div>
      </>
  );
};

export default DrawingTab;


