import React from 'react'
import { Icons } from '../../../../icons'

const CreateCheckList = ({ isOpen, onClose }) => {
    if (!isOpen) {
        return null
    }

    return (
        <div className="add-main">
            <div className="add-popup">
                {/* Popup Header */}
                <div className="add-pop-header">
                    <h1 className="add-heading">Create New Check List</h1>
                    <span onClick={onClose} className=" cursor-pointer"><Icons.cross size={20} /></span>
                </div>
                <hr className="mt-5 mx-5" />
                {/* Form */}
                <form className="p-6">
                    {/* Row 1 */}
                    <div className="add-grid-container">
                        <div>
                            <label className="add-lable">
                                Title
                            </label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="Title"
                            />
                        </div>
                        <div>
                            <label className="add-lable">
                                Date
                            </label>
                            <input
                                type="date"
                                className="add-input"
                                placeholder=" "
                            />
                        </div>
                    </div>
                     
                    <div className="add-grid-container">
                    <div>
                        <label className="add-lable">
                        Check By
                        </label>
                        <select
                            className="add-input bg-white"
                            defaultValue=""
                        >
                            <option value="" disabled>
                                Select By
                            </option>
                            <option value="new-york">New York</option>
                            <option value="los-angeles">Los Angeles</option>
                            <option value="chicago">Chicago</option>
                            <option value="houston">Houston</option>
                            <option value="miami">Miami</option>
                        </select>
                    </div>
                    </div>

                    {/* <h3 className="add-heading mb-3">Add BOQ</h3> */}
        <table className="contract-table-container">
          <thead>
            <tr className="contract-tr">
              <th className="contract-th">Sr.</th>
              <th className="contract-th">Item </th>
              <th className="contract-th">Satisfied</th>
              <th className="contract-th">Not Satisfied</th>
              <th className="contract-th">N/A</th>
              <th className="contract-th">Comments</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              
              <td className="contract-th">1 </td>
              <td className="contract-th"> </td>
              <td className="contract-th"> </td>
              <td className="contract-th"> </td>
              <td className="contract-th"> </td>
              <td className="contract-th"> </td>
            </tr>
            <tr>
            <td className="contract-th">2 </td>
              <td className="contract-th"> </td>
              <td className="contract-th"> </td>
              <td className="contract-th"> </td>
              <td className="contract-th"> </td>
              <td className="contract-th"> </td>
              
            </tr>
            <tr>
            <td className="contract-th">3 </td>
              <td className="contract-th"> </td>
              <td className="contract-th"> </td>
              <td className="contract-th"> </td>
              <td className="contract-th"> </td>
              <td className="contract-th"> </td>
              
            </tr>
            <tr>
              <td className="contract-th">Comments </td>
              
            </tr>
            <tr>
              <td className="contract-th">Approved </td>
              
            </tr>
          </tbody>
        </table>


<br/>

                    {/* Buttons */}
                    <div className="add-btn-container">
                        <button
                            type="button"
                            onClick={onClose}
                            className="in-add-cancel-btn"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="in-add-confirm-btn"
                        >
                            Save Checklist
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CreateCheckList