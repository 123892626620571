import React, { useState } from 'react'
import { Icons } from '../../../../icons'
import AddMaterial from './AddMaterial'
import AddStock from './AddStock'

const StoreHead = () => {
  const [MaterialModal, setMaterialModal] = useState(false)
  const [StockModal, setStockModal] = useState(false)

  const handleMaterialPopUp = () => {
    setMaterialModal(!MaterialModal)
  }

  const handleMaterialPopUpClose = () => {
    setMaterialModal(false)
  }

  const handleStockPopUp = () => {
    setStockModal(!StockModal)
  }

  const handleStockPopUpClose = () => {
    setStockModal(false)
  }

  return (
    <div>
      {/* Header */}
      <div className="list-header">
        <h1 className="list-heading">9 Store</h1>
        <div className=' flex gap-4'>
          {/* <button onClick={handleStockPopUp} className="list-add-btn">
            <span>Add Stock</span> <span><Icons.add size={20} /></span>
          </button> */}
          <button onClick={handleMaterialPopUp} className="list-add-btn">
            <span>Material Requisition</span> <span><Icons.add size={20} /></span>
          </button>
        </div>
      </div>
      <AddMaterial isOpen={MaterialModal} onClose={handleMaterialPopUpClose} />
      <AddStock isOpen={StockModal} onClose={handleStockPopUpClose} />
    </div>
  )
}

export default StoreHead