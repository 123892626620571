import React, { useState } from 'react';
import { Icons } from '../../icons';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate= useNavigate()

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate('/dashboard')
    // Handle login logic here
  };

  return (
    <div className='login-main-imgbg'
    >
      {/* Flex container to center the content */}
      <div className="login-main">
        <div className="login-main-widin">
          <h2>Login</h2>
          <form onSubmit={handleSubmit} className="login-form">
            <label className="login-input-lable">Email</label>
            <div className='login-input-div'>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="login-input"
            />
            <span>
              <Icons.email size={18} className="header-icon"/>
            </span>
            </div>
            <label className="login-input-lable">Password</label>
            <div className='login-input-div '>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="login-input"
            />
            <span>
              <Icons.lock size={18} className="header-icon"/>
            </span>
            </div>
            <span className='forgot-pass-btn'>Forgot password</span>
            <button
              type="submit"
              className="login-submit-btn"
            >
              Login
            </button>
          </form>
          <p className='copy-right'>Copyright.Conso Management Services Pvt. Ltd</p>
        </div>
      </div>
    </div>
  );
};

export default Login;
