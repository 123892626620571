import React from 'react';
import { assets } from '../../assets/assets';

const About = () => {
  return (<>
    <div className=' about-main'>
      <div className="about-flex-section">
        <div className="about-container">
          {/* Image Section */}
          <div className="md:w-96">
            <img
              src={assets.aboutimg1}
              alt="About Us"
            />
          </div>

          {/* Text Section */}
          <div className=" about-left-text">
            <h1 className="">
              We Are Experts
            </h1>
            <p className="">
              Our company provides a full range of services for the construction
              of private houses and cottages since 1995.
            </p>
            <p className="">
              We successfully cope with tasks of varying complexity, provide
              long-term guarantees, and regularly master new technologies. Our
              portfolio includes dozens of successfully completed projects of
              houses of different storeys, with high-quality finishes and good
              repairs. Building houses is our vocation!
            </p>
            <div className="about-btn-section">
              {/* Button Section */}
              <button>
                About Us
              </button>

              {/* Horizontal Line Section */}
              <hr className="" />
            </div>

          </div>
        </div>
      </div>
      <div className="ab-section-2">
        <div className="about-container">
          {/* Image Section */}
          <div className="md:w-[500px]">
            <img
              src={assets.aboutimg2}
              alt="About Us"
            />
          </div>

          {/* Text Section */}
          <div className=" about-left-text">
            <h1 className="about-left-text">
              Word Of The CEO
            </h1>
            <p className="">
              Over the years, we have gained a reputation as a decent, reliable
              contract and we value it very much. Each objects is special for us, even if
              its is performed according to a standard project. I sincerely believe that we
              have a brigth future ahead of us and more than one hundred successfully
              implemented projects!.
            </p>
            <p className="">
              We successfully cope with tasks of varying complexity, provide
              long-term guarantees, and regularly master new technologies. Our
              portfolio includes dozens of successfully completed projects of
              houses of different storeys, with high-quality finishes and good
              repairs. Building houses is our vocation!
            </p>
 
              {/* Oliver Johnson Section */}
              <h1 className=' about-oliver'>Oliver Johnson</h1>

              <p>CEO Company</p>


          </div>
        </div>
      </div>
    </div>
  </>);
};

export default About;
