import React from 'react'
import Total from './Total'
import BarChart from '../dashboard/BarChart'
import DoughnutChart from './DoughnutChart'
import ProjectList from '../projectinitiation/project/ProjectList'
import DashboardProjectList from './DashboardProjectList'
import Staff from './Staff'
import Labour from './Labour'

const Dashboard = () => {
  return (
    <div>
      <Total />
      <div className=' flex flex-wrap justify-between'>
        <BarChart />
        <DoughnutChart />
        <div className=' w-full mt-5'>
          <DashboardProjectList />
        </div>
        {/* <ProjectList /> */}
        <div className="sm:flex w-full sm:justify-between justify-center items-start sm:space-x-4">
          {/* Staff Section */}
          <div className="w-full flex-1">
            <Staff />
          </div>

          {/* Labour Section */}
          <div className=" w-full flex-1">
            <Labour />
          </div>

          {/* Table Section */}
          <div className="w-full flex-1 mt-[70px]">
            <div className="overflow-x-auto">
              <table className="table-in-cashflow w-full">
                <thead>
                  <tr className="bg-[#78222E] text-white">
                    <th className="list-th-common">Total Nos</th>
                  </tr>
                </thead>
                <tbody>
                  {[...Array(5)].map((_, rowIndex) => (
                    <tr key={rowIndex}>
                      {[...Array(1)].map((_, colIndex) => (
                        <td key={colIndex} className="td-cash"></td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Dashboard