import React from 'react'
import { Icons } from '../../../../icons'

const AddProject = ({ isOpen, onClose }) => {
    if (!isOpen) {
        return null
    }

    return (
        <div>
            <div className="add-main">
                <div className="add-popup scrollbar-hide">
                    {/* Popup Header */}
                    <div className="add-pop-header">
                        <h2 className="add-heading">Add New Item</h2>
                        <span onClick={onClose} className=" cursor-pointer"><Icons.cross size={20} /></span>
                    </div>

                    {/* Form */}
                    <form className="p-6">
                        {/* Row 1 */}
                        <div className="add-grid-container">
                            <div>
                                <label className="add-lable">
                                    Package Name
                                </label>
                                <input
                                    type="text"
                                    className="add-input"
                                    placeholder="Enter package name"
                                />
                            </div>
                            <div>
                                <label className="add-lable">
                                    BOQ item name
                                </label>
                                <input
                                    type="text"
                                    className="add-input"
                                    placeholder="BOQ item name"
                                />
                            </div>
                           
                        </div>

                       

                        {/* Row 2 */}
                        <div className="add-grid-container">
                        <div>
                                <label className="add-lable">
                                    BOQ item number
                                </label>
                                <input
                                    type="text"
                                    className="add-input"
                                    placeholder="BOQ item number"
                                />
                            </div>
                        <div>
                            <label className="add-lable">
                                Type
                            </label>
                            <select
                                className="add-input bg-white"
                            >
                                <option value="" disabled>
                                    Select
                                </option>
                                <option value="new-york">New York</option>
                                <option value="los-angeles">Los Angeles</option>
                                <option value="chicago">Chicago</option>
                                <option value="houston">Houston</option>
                                <option value="miami">Miami</option>
                            </select>
                        </div>
                         

                        </div>

                        {/* Row 3 */}
                        <div className="add-grid-container">
                        <div>
                                <label className="add-lable">
                                    Total Quantity
                                </label>
                                <input
                                    type="text"
                                    className="add-input"
                                    placeholder="Enter quantity"
                                />
                            </div>
                        
                        <div>
                                <label className="add-lable">
                                    Quotation 1 exl GST
                                </label>
                                <input
                                    type="text"
                                    className="add-input"
                                    placeholder="Type here..."
                                />
                            </div>
                           
                          
                        </div>
                        {/* Row 4 */}
                        <div className="add-grid-container">
                        <div>
                                <label className="add-lable">
                                    Quotation 2 exl GST
                                </label>
                                <input
                                    type="text"
                                    className="add-input"
                                    placeholder="type here..."
                                />
                            </div>
                        <div>
                                <label className="add-lable">
                                    Quotation 3 exl GST
                                </label>
                                <input
                                    type="number"
                                    className="add-input"
                                    placeholder="type here..."
                                />
                            </div>
                            
                        </div>
                        <div className="add-grid-container">
                        <div>
                                <label className="add-lable">
                                    Amount=Total QTY x Lowest Quote
                                </label>
                                <input
                                    type="number"
                                    className="add-input"
                                    placeholder=" "
                                />
                            </div>
                        <div>
                                <label className="add-lable">
                                    Attach File
                                </label>
                                <input
                                    type="file"
                                    className="add-input"
                                    accept="image/*"
                                />
                            </div>
                        </div>
                        {/* Buttons */}
                        <div className="add-btn-container">
                            <button
                                type="button"
                                onClick={onClose}
                                className="in-add-cancel-btn"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="in-add-confirm-btn"
                            >
                                Confirm
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddProject