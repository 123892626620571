import React, { useState } from "react";
import { Icons } from "../../../icons";
import { assets } from "../../../assets/assets";
import { Link } from "react-router-dom";

const MobileResponsiveSidebar = ({ isopen, onclose }) => {
  const [openMenu, setOpenMenu] = useState(null); // Track parent menus
  const [openSubMenu, setOpenSubMenu] = useState({}); // Track nested submenus
  let [activeTab, setActiveTab] = useState("Dashboard");
  const [activeSubMenu, setActiveSubMenu] = useState({}); // Track active submenu item

  const menuItems = [
    {
      name: "Dashboard",
      link: "/dashboard",
      icon: <Icons.home />,
      subMenu: [], // No submenu
    },
    {
      name: "Project Initiation",
      icon: <Icons.settings />,
      subMenu: [
        { name: "Project List", link: "projectlist" },
        { name: "Company List", link: "companylist" },
        { name: "User List", link: "userlist" },
        { name: "Contract", link: "contract-list" },
        { name: "Drawing", link: "drawing-list" },
        { name: "Planning", link: "Planning" },
      ],
    },
    {
      name: "Procurement",
      icon: <Icons.cart />,
      subMenu: [
        {
          name: "Procurement Plan",
          subMenu: [
            { name: "Procurement Plan", link: "procurement-plan" },
            { name: "Procurement Summery ", link: "procurement-summery" },
          ],
        },
        {
          name: "Store",
          subMenu: [
            { name: "Store", link: "Store" },
            { name: "Stock Inward Register", link: "Stock/Inward/Register" },
            { name: "Stock Register", link: "stock/register" },
          ],
        },
        { name: "Plant & Machinery", link: "plant-machinery" },
        { name: "Vendor Management", link: "vendor-management" },
        {
          name: "Security",
          subMenu: [
            { name: "Security", link: "security" },
            { name: "Manpower", link: "security/manpower" },
            {
              name: "Material Outward Register",
              link: "security/Material/Outward/Register",
            },
          ],
        },
      ],
    },
    {
      name: "Manpower",
      icon: <Icons.manpower />,
      subMenu: [
        { name: "Manpower Requirement", link: "manpower-requirement" },
        { name: "Attendace Report", link: "attendance-report" },
        { name: "Daily Progress Report", link: "daily-progress-report" },
      ],
    },
    {
      name: "Compliences",
      icon: <Icons.textfile />,
      subMenu: [
        { name: "Quality", link: "quality" },
        { name: "Compoliences/Insurances", link: "compoliences/insurances" },
        {
          name: "Safety",
          subMenu: [
            { name: "HSE Induction", link: "hse/Introduction" },
            { name: "HIRA", link: "HIRA" },
            { name: "Safety Training", link: "safety/training" },
            { name: "Work Methodlogy", link: "work/methodlogy" },
            { name: "Work Permits", link: "work/permits" },
            { name: "Safety Violation", link: "safety/violation" },
          ],
        },
      ],
    },
    {
      name: "MOM",
      icon: <Icons.arrowup />,
      link: "meeting",
      subMenu: [], // No submenu
    },
    {
      name: "Sales",
      icon: <Icons.linechart />,
      link: "sales",
      subMenu: [], // No submenu
    },
    {
      name: "Bill Management",
      icon: <Icons.bills />,
      subMenu: [
        {
          name: "Debit Note",
          subMenu: [
            { name: "Debit Note", link: "debit/note" },
            { name: "manpower", link: "debit/note/manpower" },
            {
              name: "Material Outward Register",
              link: "debit/note/Material/Outward/Register",
            },
          ],
        },
        { name: "Bills", link: "bills" },
        { name: "Variation", link: "variation" },
      ],
    },
    {
      name: "Help",
      icon: <Icons.help />,
      subMenu: [
        { name: "Support/Feedback", link: "feedback" },
        { name: "Access log", link: "access/log" },
      ],
    },

    {
      name: "Project Tasks",
      icon: <Icons.ProjectTask />,
      link: "project-task",
      subMenu: [], // No submenu
    },
  ];

  const toggleMenu = (index) => {
    setOpenMenu(openMenu === index ? null : index); // Toggle main menu
  };

  const toggleSubMenu = (parentIndex, subIndex) => {
    setOpenSubMenu((prev) => ({
      ...prev,
      [`${parentIndex}-${subIndex}`]: !prev[`${parentIndex}-${subIndex}`], // Toggle submenu
    }));
  };

  const renderSubMenu = (subMenu, parentIndex) => {
    return subMenu.map((subItem, subIndex) => (
      <div key={subIndex}>
        <Link
          to={subItem.link}
          className={`submenu-link ${
            activeSubMenu === subItem.name ? "active-text" : ""
          }`}
          onClick={() => {
            toggleSubMenu(parentIndex, subIndex);
            setActiveSubMenu(subItem.name);
          }}>
          <div className=" flex justify-between">
            {subItem.name}
            {subItem.subMenu && // Show toggle icon if nested submenu exists
              (openSubMenu[`${parentIndex}-${subIndex}`] ? (
                <Icons.Chevron size={15} className="mt-1" />
              ) : (
                <Icons.ChevroDown size={15} className="mt-1" />
              ))}
          </div>
        </Link>

        {/* Render nested submenu */}
        {subItem.subMenu && openSubMenu[`${parentIndex}-${subIndex}`] && (
          <div className="nested-submenu">
            {renderSubMenu(subItem.subMenu, `${parentIndex}-${subIndex}`)}
          </div>
        )}
      </div>
    ));
  };

  if (!isopen) {
    return null; // Hide sidebar when not open
  }

  return (
    <div className="fixed inset-0 z-50">
      {/* Background Overlay */}
      <div
        className="absolute inset-0 bg-black bg-opacity-50"
        onClick={onclose}></div>

      {/* Sidebar Container */}
      <div className="absolute top-0 left-0 w-64 h-full bg-white text-[#000000] shadow-lg  overflow-y-auto scrollbar-hide ">
        <div className=" flex justify-between items-center px-4">
          {/* Logo */}
          <div className="">
            <img src={assets.dashboaedlogo} alt="logo" />
          </div>
          <span onClick={onclose} className=" cursor-pointer">
            <Icons.cross size={24} />
          </span>
        </div>
        {/* Menu */}
        <nav className="sidebar-menu">
          {menuItems.map((item, index) => (
            <div key={index} className={`mb-2`}>
              {/* Parent Menu */}
              <Link
                to={item.link}
                className={`sidebar-parent-menu group ${
                  activeTab === item.name ? "active " : ""
                }`}
                onClick={() => {
                  toggleMenu(index);
                  setActiveTab(item.name);
                }}>
                <div className="side-menu-icon">
                  {item.icon}
                  <span className="">{item.name}</span>
                </div>
                {/* Toggle Icon */}
                {item.subMenu.length > 0 &&
                  (openMenu === index ? (
                    <Icons.Chevron />
                  ) : (
                    <Icons.ChevroDown />
                  ))}

                {/* Simulated Left Border */}
                <span className="side-Simulated-border"></span>
              </Link>

              {/* Submenu */}
              {openMenu === index && item.subMenu.length > 0 && (
                <div className="sidebar-submenu">
                  {renderSubMenu(item.subMenu, index)}
                </div>
              )}
            </div>
          ))}
        </nav>
        {/* Footer */}
        <div className="sidebar-logout">
          <span>
            <Icons.logout size={20} className="side-logout-icon" />
          </span>
          <span>Logout</span>
        </div>
      </div>
    </div>
  );
};

export default MobileResponsiveSidebar;
