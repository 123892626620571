import React from 'react'

const ListSecurity = () => {
  return (
    <div className=" shadow-md">
            <div className="overflow-x-auto">
                <table className="table-in-cashflow">
                    <thead>
                        <tr className="bg-[#78222E] text-white">
                            <th className="list-th-common ">Date</th>
                            <th className="list-th-common ">Vehicle Number</th>
                            <th className="list-th-common ">Vehicle Type</th>
                            <th className="list-th-common ">Name of driver</th>
                            <th className="list-th-common ">Challan</th>
                            <th className="list-th-common">
                                Company Name
                            </th>
                            <th className="list-th-common">Out Time</th>
                            <th className="list-th-common">In Time</th>
                            <th className="list-th-common">Contact</th>

                            <th className="list-th-common">Comment</th>


                        </tr>
                    </thead>
                    <tbody>
                        {[...Array(10)].map((_, rowIndex) => (
                            <tr key={rowIndex}>
                                {[...Array(10)].map((_, colIndex) => (
                                    <td
                                        key={colIndex}
                                        className="td-cash"
                                    ></td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
  )
}

export default ListSecurity