import logo from '../assets/logo.svg'
import house from '../assets/house.png'
import aboutimg1 from '../assets/about1.svg'
import aboutimg2 from '../assets/about2.png'
import dashboaedlogo from '../assets/dashlogo.png'


export const assets = {
    logo,
    house,
    aboutimg1,
    aboutimg2,
    dashboaedlogo
}