import React from 'react'
import { Icons } from '../../../../icons'

const AddTypeModal = ({isOpen,onClose}) => {
    if(!isOpen){
        return null
    }
    
  return (
    <div className="add-main">
    <div className="add-popup scrollbar-hide">
      {/* Popup Header */}
      <div className="add-pop-header">
        <h2 className="add-heading">Add Type</h2>
      <span onClick={onClose} className=" cursor-pointer"><Icons.cross size={20}/></span> 
      </div>
      <hr className="mt-5 mx-5"/>
      {/* Form */}
      <form className="p-6 space-y-2">
        {/* Row 1 */}
     
          <div>
            <label className="add-lable">
              Manpower Type
            </label>
            <input
              type="type"
              className="add-input"
              placeholder="154321"
            />
          </div>

        {/* Buttons */}
        <div className="add-btn-container">
          <button
            type="button"
            onClick={onClose}
            className="in-add-cancel-btn"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="in-add-confirm-btn"
          >
            Confirm
          </button>
        </div>
      </form>
    </div>
  </div>  )
}

export default AddTypeModal