import React from 'react'
import MaterialOutWardRegister from '../../procurementPlan/security/MaterialOutWardRegister'

const DebitNoteOutWard = () => {
  return (
    <div>
        <MaterialOutWardRegister />
    </div>
  )
}

export default DebitNoteOutWard