import React, { useState } from 'react'

const ListMachinery = () => {

    return (
        <div className=" ">
            <div className="overflow-x-auto">
                <table className="table-in-cashflow">
                    <thead>
                        <tr className="bg-[#78222E] text-white">
                            <th className="list-th-common ">Equipement Name</th>
                            <th className="list-th-common ">TPI Frequency</th>
                            <th className="list-th-common ">TPI Date</th>
                            <th className="list-th-common ">Certificate</th>
                            <th className="list-th-common ">Comments</th>
                            <th className="list-th-common">
                                Assets number
                            </th>
                            <th className="list-th-common">Inception date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {[...Array(10)].map((_, rowIndex) => (
                            <tr key={rowIndex}>
                                {[...Array(7)].map((_, colIndex) => (
                                    <td
                                        key={colIndex}
                                        className="td-cash"
                                    ></td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className=' flex justify-end pt-4'>
                <button className="send-btn">Send Reminder in Communication for TPI reminder</button>
            </div>
        </div>
    )
}

export default ListMachinery