import React from "react";
import { Icons } from "../../../../icons";

const EditComapny = ({ isOpen, isClose }) => {
    if (!isOpen) {
        return null;
    }

    return (
        <div className="add-main">
            <div className="add-popup">
                {/* Popup Header */}
                <div className="add-pop-header">
                    <h2 className="add-heading">Edit Company</h2>
                    <span onClick={isClose} className=" cursor-pointer"><Icons.cross size={20} /></span>
                </div>

                {/* Form */}
                <form className="p-6">
                    {/* Row 1 */}
                    <div className="add-grid-container">
                        <div>
                            <label className="add-lable">
                                Company Name
                            </label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="Enter project name"
                            />
                        </div>
                        <div>
                            <label className="add-lable">
                                Comapny Abbreviation
                            </label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="Enter project number"
                            />
                        </div>
                    </div>

                    {/* Row 2 */}
                    <div className="add-grid-container">
                        <div>
                            <label className="add-lable">
                                Address line 1
                            </label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="Enter Address line 1"
                            />
                        </div>
                        <div>
                            <label className="add-lable">
                            Address line 2
                            </label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="Enter Address line 2"
                            />
                        </div>

                    </div>
                    <div className="add-grid-container">
                        <div>
                            <label className="add-lable">
                                State
                            </label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="State"
                            />
                        </div>
                        <div>
                            <label className="add-lable">
                            City
                            </label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="City"
                            />
                        </div>

                    </div>

                    {/* Row 3 */}
                    <div className="add-grid-container">
                    <div>
                            <label className="add-lable">
                                Pincode
                            </label>
                            <input
                                type="number"
                                className="add-input"
                                placeholder="pincode"
                            />
                        </div>
                        <div>
                            <label className="add-lable">
                                GST No
                            </label>
                            <input
                                type="number"
                                className="add-input"
                                placeholder="GST No"
                            />
                        </div>
                        
                    </div>
                    {/* Row 4 */}
                    <div className="add-grid-container">
                    <div>
                            <label className="add-lable">
                                PAN No
                            </label>
                            <input
                                type="number"
                                className="add-input"
                                placeholder="PAN No"
                            />
                        </div>
                        <div>
                            <label className="add-lable">
                               PAN/GST
                            </label>
                            <input
                                type="file"
                                className="add-input"
                                accept="image/*"
                            />
                        </div>
                    </div>
                    {/* Row 5 */}
                    <div className="add-grid-container">
                        <div>
                            <label className="add-lable">
                                Company Profile
                            </label>
                            <input
                                type="file"
                                className="add-input"
                                accept="image/*"
                            />
                        </div>
                        <div>
                            <label className="add-lable">
                                Upload logo
                            </label>
                            <input
                                type="file"
                                className="add-input"
                                accept="image/*"
                            />
                        </div>
                    </div>
                    {/* Row 6 */}
                    <div className="add-grid-container">
                        <div>
                            <label className="add-lable">
                                Contact Number
                            </label>
                            <input
                                type="number"
                                className="add-input"
                                placeholder="Enter Number"
                            />
                        </div>


                    </div>

                    {/* Buttons */}
                    <div className="add-btn-container">
                        <button
                            type="button"
                            onClick={isClose}
                            className="in-add-cancel-btn"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="in-add-confirm-btn"
                        >
                            Confirm
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditComapny;
