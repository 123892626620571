import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { Icons } from '../../../../icons';
import PlanningTab from './PlanningTab';
import WorkProgress from './WorkProgressTab';
import WorkProgressTab from './WorkProgressTab';

const Planning = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('Planning')
    const projects = ["Project A", "Project B", "Project C", "Project D"];
    return (
        <div>
            {/* Header */}
            <div className="list-header">
                <div className="list-tab-btn">
                    <span onClick={() => setActiveTab('Planning')} className={` ${activeTab === 'Planning' ? "bg-gray-500" : ""}  px-8 py-1 rounded-md`} >8 Planning</span><span onClick={() => setActiveTab('Work-Progress')} className={` ${activeTab === 'Work-Progress' ? "bg-gray-500" : ""}  px-8 py-1 rounded-md`}>8A Work Progress</span>
                </div>
                <div className=' flex gap-4'>
                    <div className="dropdown-relative">
                        {/* Button */}
                        <button
                            className="dropdown-container-btn"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <span>Select Project</span>
                            <span>
                                {/* Replace with your Icons.arrowdropdown */}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className={`h-5 w-5 transform ${isOpen ? "rotate-180" : ""}`}
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M19 9l-7 7-7-7"
                                    />
                                </svg>
                            </span>
                        </button>

                        {/* Dropdown Menu */}
                        {isOpen && (
                            <div className="dropdown-menu-container">
                                <ul className="">
                                    {projects.map((project, index) => (
                                        <li
                                            key={index}
                                            onClick={() => {
                                                setIsOpen(false);
                                                alert(`Selected: ${project}`);
                                            }}
                                        >
                                            {project}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                    {/* <Link to="/dashboard/add-contract" className={` ${activeTab === 'cashflow' ? "hidden" : ""} list-add-btn`}>
                        Add Activity <Icons.add />
                    </Link> */}
                </div>
            </div>
            {/* Tabes render */}
            <div className='mt-4'>
                {activeTab === 'Planning' && <PlanningTab />}
                {activeTab === 'Work-Progress' && <WorkProgressTab />}
            </div>
        </div>
    )
}

export default Planning