import React from 'react'
import { Icons } from '../../../../icons'

const AddMaterial = ({ isOpen, onClose }) => {
  if (!isOpen) {
    return null
  }
  return (
    <div className="add-main">
      <div className="add-popup scrollbar-hide">
        {/* Popup Header */}
        <div className="add-pop-header">
          <h2 className="add-heading">Add Material Requisition</h2>
          <span onClick={onClose} className=" cursor-pointer"><Icons.cross size={20} /></span>
        </div>
        <hr className="mt-5 mx-5" />
        {/* Form */}
        <form className="p-6">
          {/* Row 1 */}
          <div className="add-grid-container">
            <div>
              <label className="add-lable">
                Requisition No
              </label>
              <input
                type="date"
                className="add-input"
                placeholder=" "
              />
            </div>
            <div>
              <label className="add-lable">
                Requisition By
              </label>
              <input
                type="text"
                className="add-input"
                placeholder="Enter Requisition"
              />
            </div>
          </div>

          {/* Row 2 */}
          <div className="add-grid-container">
            <div>
              <label className="add-lable">
                Date
              </label>
              <input
                type="date"
                className="add-input"
                placeholder=" "
              />
            </div>
            <div>
              <label className="add-lable">
                Date required
              </label>
              <input
                type="date"
                className="add-input"
                placeholder=" "
              />
            </div>
          </div>

          {/* Row 3 */}
          <div className="add-grid-container">
            <div>
              <label className="add-lable">
                Quantity
              </label>
              <input
                type="number"
                className="add-input"
                placeholder='quantity'
              />
            </div>
            <div>
              <label className="add-lable">
                Unit
              </label>
              <input
                type="text"
                className="add-input"
                placeholder="Enter unit"
              />
            </div>
          </div>

          <div>
            <label className="add-lable">
              Description
            </label>
            <input
              type="text"
              className="add-input"
              placeholder='type here'
            />
          </div>
          <div className="add-grid-container">
            <div>
              <label className="add-lable">
                Sr No
              </label>
              <input
                type="number"
                className="add-input"
                placeholder=' '
              />
            </div>
            
          </div>
          <button
              className="in-add-confirm-btn"
            >
              Add new Item
            </button>

          {/* Buttons */}
          <div className="add-btn-container">
            <button
              type="button"
              onClick={onClose}
              className="in-add-cancel-btn"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="in-add-confirm-btn"
            >
              Confirm
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddMaterial