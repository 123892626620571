import React, { useState } from 'react';
import { Icons } from '../../../../icons';

const IncurenceTable = () => {
    // Separate state for each dropdown
    const [requiredOpen, setRequiredOpen] = useState(false);
    const [frequencyOpen, setFrequencyOpen] = useState(false);
    const [expiryOpen, setExpiryOpen] = useState(false);

    // Toggle functions for each dropdown
    const toggleRequired = () => setRequiredOpen(!requiredOpen);
    const toggleFrequency = () => setFrequencyOpen(!frequencyOpen);
    const toggleExpiry = () => setExpiryOpen(!expiryOpen);

    return (
        <div className="shadow-md">
            <div className="overflow-x-auto">
                <table className="table-in-cashflow">
                    <thead>
                        <tr className="bg-[#78222E] text-white">
                            {/* Required Dropdown */}
                            <th className="list-th-common relative">
                                <span
                                    className="dropdown-table-btn flex justify-center items-center"
                                    onClick={toggleRequired}
                                >
                                    Required{' '}
                                    {requiredOpen ? (
                                        <Icons.arrowclose size={24} />
                                    ) : (
                                        <Icons.arroopen size={24} />
                                    )}
                                </span>
                                {requiredOpen && (
                                    <ul className="dropdown-table-menu-head">
                                        <li className="dropdown-table-menu-text">Doctor visit</li>
                                        <li className="dropdown-table-menu-text">pf/esic</li>
                                        <li className="dropdown-table-menu-text">Labour licence</li>
                                        <li className="dropdown-table-menu-text">Muster</li>
                                    </ul>
                                )}
                            </th>

                            {/* Frequency Dropdown */}
                            <th className="list-th-common relative">
                                <span
                                    className="dropdown-table-btn flex justify-center items-center"
                                    onClick={toggleFrequency}
                                >
                                    Frequency{' '}
                                    {frequencyOpen ? (
                                        <Icons.arrowclose size={24} />
                                    ) : (
                                        <Icons.arroopen size={24} />
                                    )}
                                </span>
                                {frequencyOpen && (
                                    <ul className="dropdown-table-menu-head">
                                        <li className="dropdown-table-menu-text">Daily</li>
                                        <li className="dropdown-table-menu-text">Weekly</li>
                                    </ul>
                                )}
                            </th>

                            {/* Expiry Dropdown */}
                            <th className="list-th-common relative">
                                <span
                                    className="dropdown-table-btn flex justify-center items-center"
                                    onClick={toggleExpiry}
                                >
                                    Expiry{' '}
                                    {expiryOpen ? (
                                        <Icons.arrowclose size={24} />
                                    ) : (
                                        <Icons.arroopen size={24} />
                                    )}
                                </span>
                                {expiryOpen && (
                                    <ul className="dropdown-table-menu-head">
                                        <li className="dropdown-table-menu-text">Monthly</li>
                                        <li className="dropdown-table-menu-text">Yearly</li>
                                    </ul>
                                )}
                            </th>

                            <th className="list-th-common">Renewal</th>
                            <th className="list-th-common">Remark</th>
                        </tr>
                    </thead>
                    <tbody>
                        {[...Array(8)].map((_, rowIndex) => (
                            <tr key={rowIndex}>
                                {[...Array(5)].map((_, colIndex) => (
                                    <td key={colIndex} className="td-cash"></td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default IncurenceTable;
