import React from 'react'
import { Icons } from '../../icons'

const AddMeeting = ({isOpen, onClose}) => {
  if(!isOpen){
    return null
  }
  return (
   <div className="add-main">
          <div className="add-popup ">
            {/* Popup Header */}
            <div className="add-pop-header">
              <h2 className="add-heading">Add Meeting</h2>
              <span onClick={onClose} className=" cursor-pointer"><Icons.cross size={20} /></span>
            </div>
            <hr className="mt-5 mx-5" />
            {/* Form */}
            <form className="p-6">
              {/* Row 1 */}
              <div className="add-grid-container">
                <div>
                  <label className="add-lable">
                    Meeting Date
                  </label>
                  <input
                    type="date"
                    className="add-input"
                    placeholder=""
                  />
                </div>
                <div>
                  <label className="add-lable">
                  Agenda
                  </label>
                  <input
                    type="text"
                    className="add-input"
                    placeholder=""
                  />
                </div>
               
              </div>
    
              {/* Row 2 */}
              <div className="add-grid-container">
              <div>
                  <label className="add-lable">
                  Due Date	

                  </label>
                  <input
                    type="date"
                    className="add-input"
                    placeholder=" "
                  />
                </div>
              <div>
                    <label className="add-lable">
                        Status
                    </label>
                    <select
                        className="add-input bg-white"
                        defaultValue=""
                    >
                        <option value="" disabled>
                            Select 
                        </option>
                        <option value="new-york">Compelete</option>
                        <option value="los-angeles">Incompelete</option>
                    </select>
                </div>
              </div>
   
              {/* Buttons */}
              <div className="add-btn-container">
                <button
                  type="button"
                  onClick={onClose}
                  className="in-add-cancel-btn"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="in-add-confirm-btn"
                >
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>  )
}

export default AddMeeting