import React, { useState } from 'react';
import { Icons } from '../../../../icons';
import AddProject from './AddProject';
import EditProject from './EditProject';
import Card from '../../../../components/dashboard/card/Card';

const ProjectList = () => {
  const projects = [
    {
        id: 1,
        name: 'Green Valley',
        number: 'GV001',
        address: '123 Maple Street, Cityville',
        area: '2500 sqft',
        image: 'https://via.placeholder.com/50',
        projectcost: '1500000',
        expenditure: '300000',
    },
    {
        id: 2,
        name: 'Blue Sky Apartments',
        number: 'BS002',
        address: '456 Oak Avenue, Townsville',
        area: '3200 sqft',
        image: 'https://via.placeholder.com/50',
        projectcost: '1500000',
        expenditure: '300000',
    }
];

  const [AddProjectModal, setAddProjectModal] = useState(false)
  const [EditProjectModal, setEditProjectModal] = useState(false)

  const handleProjectModel = () => {
    setAddProjectModal(!AddProjectModal)
  }
  const handleProjecClose = () => {
    setAddProjectModal(false)
  }
  const handleEditProjectModel = () => {
    setEditProjectModal(!EditProjectModal)
  }


  const handleEditProjecClose = () => {
    setEditProjectModal(false)
  }

  const handleEdit = (id) => {
    console.log(`Edit project with ID: ${id}`);
};

const handleDelete = (id) => {
    console.log(`Delete project with ID: ${id}`);
};

  return (
    <div className="">
      {/* Header */}
      <div className="list-header">
        <h1 className="list-heading" >3C Project List</h1>
        <button style={{width:'180px',height:'40px'}} onClick={handleProjectModel} className="list-add-btn">
          <span>Add Project</span> <span><Icons.add size={20} /></span>
        </button>
      </div>

      {/* Table */}
      <div className="list-table-head">
                <table className="w-full text-left border-collapse">
                    <thead>
                        <tr className="list-table-container">
                            <th className="list-th">Project Name</th>
                            <th className="list-th">Project Number</th>
                            <th className="list-th">Address</th>
                            <th className="list-th">Project Area (Sqft)</th>
                            <th className="list-th">Project Image</th>
                            <th className="list-th">Project cost</th>
                            <th className="list-th">Expenditure</th>
                            <th className="list-th">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {projects.map((project, index) => (
                            <tr key={index} className="border-t text-gray-600">
                                <td className="list-th">{project.name}</td>
                                <td className="list-th">{project.number}</td>
                                <td className="list-th">{project.address}</td>
                                <td className="list-th">{project.area}</td>
                                <td className="list-th">
                                    <img
                                        src={project.image}
                                        alt={project.name}
                                        className="list-img"
                                    />
                                </td>
                                <td className="list-th">{project.projectcost}</td>
                                <td className="list-th">{project.expenditure}</td>
                                <td className="list-th list-action">
                                    <Icons.edit onClick={handleEditProjectModel} className="cursor-pointer" size={24} />
                                    <Icons.read size={24} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {/* <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 sm:hidden">
            {projects.map((project) => (
                <Card
                    key={project.id}
                    title={project.name}
                    subtitle={`Project Number: ${project.number}`}
                    description={`Address: ${project.address}\nArea: ${project.area}`}
                    image={project.image}
                    actions={[
                        {
                            label: <Icons.edit size={20} />,
                            onClick: () => handleEdit(project.id),
                            className: '',
                        },
                        {
                            label: <Icons.read size={20} />,
                            onClick: () => handleDelete(project.id),
                            className: '',
                        }
                    ]}
                />
            ))}
        </div> */}
      <AddProject isOpen={AddProjectModal} onClose={handleProjecClose} />
      <EditProject isOpen={EditProjectModal} onClose={handleEditProjecClose} />

    </div>
  );
};

export default ProjectList;
