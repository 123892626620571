import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { Icons } from '../../../../icons';
import DrawingTab from './DrawingTab';
import FolderTab from '../drawings/FolderTab'
import AddDrawingModal from './AddDrawingModal';

const Drawings = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [drowingpopup,setDrawingPopUp] = useState(false)
    const [activeTab, setActiveTab] = useState('drawings')
    const projects = ["Project A", "Project B", "Project C", "Project D"];

    const handleDrawingModal = () =>{
        setDrawingPopUp(!drowingpopup)
    }

    const handleClose = () =>{
        setDrawingPopUp(false)
    }
    return (
        <div>
            {/* Header */}
            <div className="list-header">
                <div className="list-tab-btn">
                    <span onClick={() => setActiveTab('drawings')} className={` ${activeTab === 'drawings' ? "bg-gray-500" : ""}  md:px-8 px-3 py-1 rounded-md`} >6 Drawings</span><span onClick={() => setActiveTab('folder')} className={` ${activeTab === 'folder' ? "bg-gray-500" : ""}  md:px-8 py-1 px-3 rounded-md`}>6B Folder</span>
                </div>
                <div className=' flex gap-4'>
                    <div className="dropdown-relative">
                        {/* Button */}
                        <button
                            className="dropdown-container-btn"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <span>Select Project</span>
                            <span>
                                {/* Replace with your Icons.arrowdropdown */}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className={`h-5 w-5 transform ${isOpen ? "rotate-180" : ""}`}
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M19 9l-7 7-7-7"
                                    />
                                </svg>
                            </span>
                        </button>

                        {/* Dropdown Menu */}
                        {isOpen && (
                            <div className="dropdown-menu-container">
                                <ul>
                                    {projects.map((project, index) => (
                                        <li
                                            key={index}                                            onClick={() => {
                                                setIsOpen(false);
                                                alert(`Selected: ${project}`);
                                            }}
                                        >
                                            {project}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                    {activeTab === "drawings" ?(
                    <button onClick={handleDrawingModal}  className={` ${activeTab === 'cashflow' ? "hidden" : ""} list-add-btn`}>
                        Add Drawing <Icons.add />
                    </button>
                    ):""}
                </div>
            </div>
            {/* Tabes render */}
            <div className='mt-4'>
                {activeTab === 'drawings' && <DrawingTab />}
                {activeTab === 'folder' && <FolderTab />}
            </div>

            <AddDrawingModal isOpen={drowingpopup} onClose={handleClose} />
        </div>
    )
}

export default Drawings