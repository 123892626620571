import React from 'react'
import { Icons } from '../../../../icons'

const QualityTab = () => {
  return (
    <div className="">
    {/* Table */}
    <div className="list-table-head">
      <table className="list-table">
        <thead className=''>
          <tr className="list-table-container">
            <th className="list-th">Item No.</th>
            <th className="list-th">Test Name</th>
            <th className="list-th">Area</th>
            <th className="list-th">Identification Number</th>
            <th className="list-th">Required</th>
            <th className="list-th">Test Result</th>
            <th className="list-th">Unit</th>
            <th className="list-th">Status</th>
            <th className="list-th">Test Result</th>
          </tr>
        </thead>
        <tbody>
          {/* Example Row */}
          <tr className="list-tr">
            <td className="list-th">01</td>
            <td className="list-th">-</td>
            <td className="list-th">-</td>
            <td className="list-th">-</td>
            <td className="list-th">-</td>
            <td className="list-th">-</td>

            <td className="list-th">-</td>
            <td className="list-th"> <select className="select-div">
              <option >Select</option>
              <option>Fail</option>
              <option>Pass</option>
            </select>
            </td>
            <td className="list-th"><Icons.attechfile /></td>
          </tr>
        </tbody>
      </table>
    </div>
      {/* Send Button */}
      <div className=' flex justify-end mt-4'>
      <h1 className='send-btn'>Send as message</h1>
      </div>
  </div>
  )
}

export default QualityTab