import React, { useState } from 'react';
import { Icons } from '../../../../icons'; import { Link, useNavigate } from 'react-router-dom';
;


const SetAuthority = () => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate()
    const projects = ["Project A", "Project B", "Project C", "Project D"];
    const options = ["Option 1", "Option 2", "Option 3", "Option 4"];

    return (
        <div className="p-2">
            {/* Header */}
            <div className="list-header">
            <h1 className='list-heading flex gap-4'> <Icons.arrowleft size={20} onClick={()=> navigate('/dashboard/userlist')} className='mt-1 cursor-pointer'/>  3E Set Authority</h1>
                <div className=' flex gap-4'>
                    <div className="dropdown-relative">
                        {/* Button */}
                        <button
                            className="dropdown-container-btn"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <span>Select Project</span>
                            <span>
                                {/* Replace with your Icons.arrowdropdown */}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className={`h-5 w-5 transform ${isOpen ? "rotate-180" : ""}`}
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M19 9l-7 7-7-7"
                                    />
                                </svg>
                            </span>
                        </button>

                        {/* Dropdown Menu */}
                        {isOpen && (
                            <div className="dropdown-menu-container">
                                <ul className="">
                                    {projects.map((project, index) => (
                                        <li
                                            key={index}
                                            onClick={() => {
                                                setIsOpen(false);
                                                alert(`Selected: ${project}`);
                                            }}
                                        >
                                            {project}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="list-table-head">
                {/* Header */}
                <h1 className="form-heading">
                    Set Authority
                </h1>

                {/* Form */}
                <form className="form-container">
                    {/* Contract Checked by */}
                    <div className="form-head">
                        <label
                            className="form-lable"
                            htmlFor="dropdown-checked"
                        >
                            Contract Checked by
                        </label>
                        <select
                            id="dropdown-checked"
                            className="form-select-option"
                            defaultValue=""
                        >
                            <option value="" disabled>
                                Select an option
                            </option>
                            {options.map((option, i) => (
                                <option key={i} value={option.toLowerCase().replace(/\s+/g, "-")}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Contract Approved by */}
                    <div className="form-head">
                        <label
                            className="form-lable"
                            htmlFor="dropdown-approved"
                        >
                            Contract Approved by
                        </label>
                        <select
                            id="dropdown-approved"
                            className="form-select-option"
                            defaultValue=""
                        >
                            <option value="" disabled>
                                Select an option
                            </option>
                            {options.map((option, i) => (
                                <option key={i} value={option.toLowerCase().replace(/\s+/g, "-")}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Bill Checked By*/}
                    <div className="form-head">
                        <label
                            className="form-lable"
                            htmlFor="dropdown-approved"
                        >
                            Bill Checked By
                        </label>
                        <select
                            id="dropdown-approved"
                            className="form-select-option"
                            defaultValue=""
                        >
                            <option value="" disabled>
                                Select an option
                            </option>
                            {options.map((option, i) => (
                                <option key={i} value={option.toLowerCase().replace(/\s+/g, "-")}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Bill Certify by*/}
                    <div className="form-head">
                        <label
                            className="form-lable"
                            htmlFor="dropdown-approved"
                        >
                            Bill Certify by
                        </label>
                        <select
                            id="dropdown-approved"
                            className="form-select-option"
                            defaultValue=""
                        >
                            <option value="" disabled>
                                Select an option
                            </option>
                            {options.map((option, i) => (
                                <option key={i} value={option.toLowerCase().replace(/\s+/g, "-")}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/*Bill Approval By*/}
                    <div className="form-head">
                        <label
                            className="form-lable"
                            htmlFor="dropdown-approved"
                        >
                            Bill Approval By
                        </label>
                        <select
                            id="dropdown-approved"
                            className="form-select-option"
                            defaultValue=""
                        >
                            <option value="" disabled>
                                Select an option
                            </option>
                            {options.map((option, i) => (
                                <option key={i} value={option.toLowerCase().replace(/\s+/g, "-")}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Material Requisition Approval (9) By*/}
                    <div className="form-head">
                        <label
                            className="form-lable"
                            htmlFor="dropdown-approved"
                        >
                            Material Requisition Approval (9) By
                        </label>
                        <select
                            id="dropdown-approved"
                            className="form-select-option"
                            defaultValue=""
                        >
                            <option value="" disabled>
                                Select an option
                            </option>
                            {options.map((option, i) => (
                                <option key={i} value={option.toLowerCase().replace(/\s+/g, "-")}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>
                    {/* Gate pass approval*/}
                    <div className="form-head">
                        <label
                            className="form-lable"
                            htmlFor="dropdown-approved"
                        >
                            Gate pass approval
                        </label>
                        <select
                            id="dropdown-approved"
                            className="form-select-option"
                            defaultValue=""
                        >
                            <option value="" disabled>
                                Select an option
                            </option>
                            {options.map((option, i) => (
                                <option key={i} value={option.toLowerCase().replace(/\s+/g, "-")}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-head">
                        <label
                            className="form-lable"
                            htmlFor="dropdown-approved"
                        >
                          Add  Security
                        </label>
                        <select
                            id="dropdown-approved"
                            className="form-select-option"
                            defaultValue=""
                        >
                            <option value="" disabled>
                                Select an option
                            </option>
                            {options.map((option, i) => (
                                <option key={i} value={option.toLowerCase().replace(/\s+/g, "-")}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>
                    {/* Submit Button */}
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            className="save-btn"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SetAuthority;
