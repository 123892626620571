import React, { useState } from 'react'
import { Icons } from '../../../icons'
import Select from "react-select"; // Correct import for react-select


const AddSafetyTrainingDetails = ({isOpen,onClose}) => {
        const options = [
            { value: "Abc", label: "ab" },
            { value: "Xyz", label: "xy" },
            { value: "mn", label: "mn" },
            { value: "svelte", label: "Svelte" },
            { value: "svelte", label: "Svelte" },
    
    
            { value: "svelte", label: "Svelte" },
    
        ];
    
        // State to handle selected option
        const [selectedOption, setSelectedOption] = useState(null);
    
        // Function to handle change
        const handleChange = (option) => {
            setSelectedOption(option);
            console.log("Selected:", option);
        };

    if(!isOpen){
        return null
    }

      // Custom styles for the Select component
   const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? "1px solid #78222E17" : "1px solid #ccc", // Change border color on focus
      outline: state.isFocused ? "1px solid #78222E17" : "none", // Change outline color on focus
      boxShadow: state.isFocused ? "#78222E17" : "none", // Box-shadow on focus
      transition: "border-color 0.2s ease, box-shadow 0.2s ease", // Smooth transition for border and shadow
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#78222E17" : "#fff", // Background color for focused option
      color: state.isFocused ? "#78222E" : "#000", // Color for focused option
    }),
    menu: (provided) => ({
      ...provided,
      maxHeight: "200px", // Set max height for the dropdown
      overflowY: "auto", // Enable vertical scrolling
      borderRadius: "0px", // Optional: remove border radius if needed
    }),
    menuList: (provided) => ({
      ...provided,
      padding: "0", // Remove any default padding
    }),
  };
  

  return (
 <div className="add-main">
          <div className="add-popup ">
            {/* Popup Header */}
            <div className="add-pop-header">
              <h2 className="add-heading">Add Details</h2>
              <span onClick={onClose} className=" cursor-pointer"><Icons.cross size={20} /></span>
            </div>
            <hr className="mt-5 mx-5" />
            {/* Form */}
            <form className="p-6">
              {/* Row 1 */}
              <div className="add-grid-container">
                <div>
                  <label className="add-lable">
                    Date
                  </label>
                  <input
                    type="date"
                    className="add-input"
                    placeholder=" "
                  />
                </div>
                <div>
                  <label className="add-lable">
                  Name of person	
                  </label>
                  <input
                    type="email"
                    className="add-input"
                    placeholder="Enter Name"
                  />
                </div>
               
              </div>
    
              {/* Row 2 */}
              <div className="add-grid-container">
              <div>
                  <label className="add-lable">
                  Designation                  </label>
                  <input
                    type="text"
                    className="add-input"
                    placeholder="Enter Designation"
                  />
                </div>
              <div>
                    <label className="add-lable">
                        Company Name
                    </label>
                    <Select
                              
                                options={options} // Options for the dropdown
                                value={selectedOption} // Controlled selected value
                                onChange={handleChange} // Callback on selection change
                                isClearable // Add a clear button
                                styles={customStyles} // Apply custom styles here

                                placeholder="Select company Name..." // Placeholder text
                            />
                </div>
                <div>
                    <label className="add-lable">
                    Signature
                    </label>
                    <input
                    type="file"
                    className="add-input"
                    placeholder=""
                  />
                </div>
                <div>
                  <label className="add-lable">
                   Comments
                  </label>
                  <textarea
                    type="text"
                    className="add-input"
                    placeholder=""
                  />
                </div>
    
              </div>

    
              {/* Buttons */}
              <div className="add-btn-container">
                <button
                  type="button"
                  onClick={onClose}
                  className="in-add-cancel-btn"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="in-add-confirm-btn"
                >
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>  )
}

export default AddSafetyTrainingDetails