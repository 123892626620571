import React from 'react'
import Header from './components/header/Header'
import Home from './pages/home/Home'
import Login from './pages/login/Login'
import About from './pages/about/About'
import Footer from './components/footer/Footer'
import Signup from './pages/signup/Signup'
import { Route, Routes, useLocation } from 'react-router-dom'
import CompanyList from './pages/dashboardscrees/projectinitiation/company/CompanyList'
import MainLayout from './components/dashboard/mainlayout/MainLayout'
import Dashboard from './pages/dashboardscrees/dashboard/Dashboard'
import ProjectList from './pages/dashboardscrees/projectinitiation/project/ProjectList'
import UserList from './pages/dashboardscrees/projectinitiation/user/UserList'
import SetAuthority from './pages/dashboardscrees/projectinitiation/user/SetAuthority'
import ContractList from './pages/dashboardscrees/projectinitiation/contract/ContractList'
import AddContract from './pages/dashboardscrees/projectinitiation/contract/AddContract'
import Drawings from './pages/dashboardscrees/projectinitiation/drawings/Drawings'
import Planning from './pages/dashboardscrees/projectinitiation/planing/Planning'
import ProcurementPlan from './pages/dashboardscrees/procurementPlan/procurement/ProcurementPlan'
import VendorManagement from './pages/dashboardscrees/procurementPlan/vendorManagement/VendorManagementList'
import Security from './pages/dashboardscrees/procurementPlan/security/Security'
import PlantMachinery from './pages/dashboardscrees/procurementPlan/plant&machinery/PlantMachinery'
import Store from './pages/dashboardscrees/procurementPlan/store/Store'
import Manpower from './pages/dashboardscrees/manpower/Manpower&Requirement/Manpower'
import AttendanceReport from './pages/dashboardscrees/manpower/AttendanceReport/AttendanceReport'
import DailyProgressReport from './pages/dashboardscrees/manpower/DailyProgress/DailyProgressReport'
import QualityHeader from './pages/dashboardscrees/compliences/quality/QualityHeader'
import IncurenceHeader from './pages/dashboardscrees/compliences/incurence/IncurenceHeader'
import Safety from './pages/dashboardscrees/safety/Safety'
import MOM from './pages/dashboardscrees/MOM'
import Sales from './pages/dashboardscrees/sales/Sales'
import Bill from './pages/dashboardscrees/billmanagement/Bill'
import AccessLog from './pages/dashboardscrees/help/acceslog/AccessLog'
import SupportFeedBack from './pages/dashboardscrees/help/supportfeedback/SupportFeedBack'
import ProjectForm from './pages/dashboardscrees/projectTask/ProjectForm'
import StockInward from './pages/dashboardscrees/procurementPlan/store/StockInward'
import StockRegister from './pages/dashboardscrees/procurementPlan/store/StockRegister'
import ManPower from './pages/dashboardscrees/procurementPlan/security/ManPower'
import MaterialOutWardRegister from './pages/dashboardscrees/procurementPlan/security/MaterialOutWardRegister'
import HSEIntroduction from './pages/dashboardscrees/safety/HSEInduction'
import RiskAssessment from './pages/dashboardscrees/safety/RiskAssessment'
import SafetyTraining from './pages/dashboardscrees/safety/SafetyTraining'
import WorkMethodlogy from './pages/dashboardscrees/safety/WorkMethodlogy'
import WorkPermits from './pages/dashboardscrees/safety/WorkPermits'
import SafetyViolation from './pages/dashboardscrees/safety/SafetyViolation'
import Variation from './pages/dashboardscrees/billmanagement/Variation'
import DebitNote from './pages/dashboardscrees/billmanagement/debitnote/DebitNote'
import DebitNoteManpower from './pages/dashboardscrees/billmanagement/debitnote/DebitNoteManpower'
import DebitNoteOutWard from './pages/dashboardscrees/billmanagement/debitnote/DebitNoteOutWard'
import ProjectTable from './pages/dashboardscrees/projectTask/ProjectTable'
import ProcurementSummery from './pages/dashboardscrees/procurementPlan/procurement/ProcurementSummery'
import DrawingName from './pages/dashboardscrees/projectinitiation/drawings/DrawingName'
import StockList from './pages/dashboardscrees/procurementPlan/store/StockList'
import Visitor from './pages/dashboardscrees/procurementPlan/security/Visitor'
import NewPlantMachinery from './pages/dashboardscrees/procurementPlan/plant&machinery/NewPlantMachinery'
import LabourList from './pages/dashboardscrees/procurementPlan/security/Labour'
import GetPass from './pages/dashboardscrees/procurementPlan/security/GetPass'
const App = () => {
  const { pathname } = useLocation()


  return (<>
    <div>
      {pathname.includes("/dashboard") || <Header />}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/dashboard/*' element={<MainLayout />} >
          <Route index element={<Dashboard />} />
          <Route path='companylist' element={<CompanyList />} />
          <Route path='projectlist' element={<ProjectList />} />
          <Route path='setauthority' element={<SetAuthority />} />
          <Route path='userlist' element={<UserList />} />
          <Route path='contract-list' element={<ContractList />} />
          <Route path='add-contract' element={<AddContract />} />
          <Route path='drawing-list' element={<Drawings />} />
          <Route path='Planning' element={<Planning />} />
          <Route path='procurement-plan' element={<ProcurementPlan />} />
          <Route path='vendor-management' element={<VendorManagement />} />
          <Route path='security' element={<Security />} />
          <Route path='plant-machinery' element={<PlantMachinery />} />
          <Route path='store' element={<Store />} />
          <Route path='manpower-requirement' element={<Manpower />} />
          <Route path='attendance-report' element={<AttendanceReport />} />
          <Route path='daily-progress-report' element={<DailyProgressReport />} />
          <Route path='quality' element={<QualityHeader />} />
          <Route path='compoliences/insurances' element={<IncurenceHeader />} />
          <Route path='safety' element={<Safety />} />
          <Route path='meeting' element={<MOM />} />
          <Route path='sales' element={<Sales />} />
          <Route path='bills' element={<Bill />} />
          <Route path='access/log' element={<AccessLog />} />
          <Route path='feedback' element={<SupportFeedBack />} />
          <Route path='project-task' element={<ProjectTable />} />
          <Route path='project-mail' element={<ProjectForm />} />
          <Route path='drawing-name' element={<DrawingName />} />
          <Route path='stock-list' element={<StockList />} />
          <Route path='plant-machinery-list' element={<NewPlantMachinery />} />
          <Route path='Visitor' element={<Visitor />} />
          <Route path='Labour-list' element={<LabourList />} />
          <Route path='Getpass' element={<GetPass />} />

          <Route path='Stock/Inward/Register' element={<StockInward />} />
          <Route path='stock/register' element={<StockRegister />} />
          <Route path='security/manpower' element={<ManPower />} />
          <Route path='security/Material/Outward/Register' element={<MaterialOutWardRegister />} />
          <Route path='hse/Introduction' element={<HSEIntroduction />} />
          <Route path='HIRA' element={<RiskAssessment />} />
          <Route path='safety/training' element={<SafetyTraining />} />
          <Route path='work/methodlogy' element={<WorkMethodlogy />} />
          <Route path='work/permits' element={<WorkPermits />} />
          <Route path='safety/violation' element={<SafetyViolation />} />
          <Route path='variation' element={<Variation />} />
          <Route path='debit/note' element={<DebitNote />} />
          <Route path='debit/note/manpower' element={<DebitNoteManpower />} />
          <Route path='debit/note/Material/Outward/Register' element={<DebitNoteOutWard />} />
          <Route path='procurement-summery' element={<ProcurementSummery />} />
        </Route>
      </Routes>

      {pathname.includes("/dashboard") || <About />}
      {pathname.includes("/dashboard") || <Footer />}

    </div>

  </>)
}

export default App
