import React from 'react'
import ManpowerHead from './ManpowerHead'
import ManpowerRequirement from './ManpowerRequirement'
import ManpowerChart from './ManpowerChart'

const Manpower = () => {
  return (
    <div>
        <ManpowerHead />
        <ManpowerRequirement />
        <ManpowerChart />
    </div>
  )
}

export default Manpower