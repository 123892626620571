import React from 'react'
import { Icons } from '../../../../icons'

const DebitNote = () => {
  return (
    <div className="">
        {/* Issue to send Debit not 7j */}
    {/* Header */}
         <div className="list-header">
           <h1 className="list-heading" >21 Debit Note</h1>
           <button className="list-add-btn">
             <span>Issue to send Debit not 7j</span> <span><Icons.sendarrow size={20} /></span>
           </button>
         </div>
 <div className="overflow-x-auto">

     <table className="table-in-cashflow">
         <thead>
             <tr className="bg-[#78222E] text-white">
                 <th className="list-th-common ">Date</th>
                 <th className="list-th-common ">Item</th>
                 <th className="list-th-common ">Amount</th>
                 <th className="list-th-common ">Attach File</th>
                 <th className="list-th-common ">Company Name</th>
             </tr>
         </thead>
         <tbody>
             {[...Array(5)].map((_, rowIndex) => (
                 <tr key={rowIndex}>
                     {[...Array(5)].map((_, colIndex) => (
                         <td
                             key={colIndex}
                             className="td-cash"
                         ></td>
                     ))}
                 </tr>
             ))}
         </tbody>
     </table>
 </div>
</div>
  )
}

export default DebitNote