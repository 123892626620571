import React from 'react'

const GetPass = () => {
  return (
    <div>    <div className="list-header">
    <h1 className="list-heading">Getpass</h1>

  </div>  
<div className=" shadow-md">
<div className="overflow-x-auto">
    <table className="table-in-cashflow">
        <thead>
            <tr className="bg-[#78222E] text-white">
                <th className="list-th-common ">S. No.</th>
                <th className="list-th-common ">Company Name</th>
                <th className="list-th-common ">Description </th>
                <th className="list-th-common ">Qty</th>
                <th className="list-th-common ">Unit</th>
                <th className="list-th-common ">Remark</th>



            </tr>
        </thead>
        <tbody>
            {[...Array(10)].map((_, rowIndex) => (
                <tr key={rowIndex}>
                    {[...Array(6)].map((_, colIndex) => (
                        <td
                            key={colIndex}
                            className="td-cash"
                        ></td>
                    ))}
                </tr>
            ))}
        </tbody>
    </table>
</div>
</div></div>
  )
}

export default GetPass