import React, { useState } from 'react'
import { Icons } from '../../icons';
import AddMeeting from '../dashboardscrees/AddMeeting'

const MOM = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [overdue, setOverdue] = useState(false);
    const [addmeeting, setAddMeeting] = useState(false)

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const toggleStatus = () => setStatus(!status);
    const toggleOverDue = () => setOverdue(!overdue);

    const handleMeeting = () =>{
        setAddMeeting(!addmeeting)
    }

    const handleCloseMeeting = () =>{
        setAddMeeting(false)
    }

    return (
        <div className="">
            {/* Header */}
            <div className="list-header">
                <h1 className="list-heading" >15 Minutes of Meeting</h1>
                <button onClick={handleMeeting} className="list-add-btn">
                    <span>Add Meeting</span> <span><Icons.add size={20} /></span>
                </button>
            </div>
            <div className="overflow-x-auto">

                <table className="table-in-cashflow">
                    <thead>
                        <tr className="bg-[#78222E] text-white">
                            <th colSpan="3" className="list-th-common ">Add New Meeting</th>
                            <th colSpan="3" className="list-th-common ">Meeting Number</th>
                        </tr>
                        <tr className="bg-[#78222E] text-white">
                            <th className="list-th-common ">Date</th>
                            <th className="list-th-common ">Agenda</th>
                            <th className="list-th-common ">Due Date</th>
                            <th className="list-th-common relative">
                                <span className="dropdown-table-btn flex justify-center items-center" onClick={toggleStatus}>
                                    Due By{" "}
                                    {status ? (
                                        <Icons.arrowclose size={24} />
                                    ) : (
                                        <Icons.arroopen size={24} />
                                    )}
                                </span>
                                {status && (
                                    <ul className="dropdown-table-menu-head">
                                        <li className="dropdown-table-menu-text">User Name</li>
                                        <li className="dropdown-table-menu-text">Send Reminder on Task</li>
                                    </ul>
                                )}
                            </th>
                            <th className="list-th-common relative">
                                <span className="dropdown-table-btn flex justify-center items-center" onClick={toggleOverDue}>
                                    Status{" "}
                                    {overdue ? (
                                        <Icons.arrowclose size={24} />
                                    ) : (
                                        <Icons.arroopen size={24} />
                                    )}
                                </span>
                                {overdue && (
                                    <ul className="dropdown-table-menu-head">
                                        <li className="dropdown-table-menu-text">Compelete</li>
                                        <li className="dropdown-table-menu-text">Incomplete</li>
                                    </ul>
                                )}
                            </th>
                            <th className="list-th-common relative">
                                <span className="dropdown-table-btn flex justify-center items-center" onClick={toggleDropdown}>
                                    Overdue{" "}
                                    {isOpen ? (
                                        <Icons.arrowclose size={24} />
                                    ) : (
                                        <Icons.arroopen size={24} />
                                    )}
                                </span>
                                {isOpen && (
                                    <ul className="dropdown-table-menu-head">
                                        <li className="dropdown-table-menu-text">Due date</li>
                                        <li className="dropdown-table-menu-text">Current Date</li>
                                    </ul>
                                )}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {[...Array(4)].map((_, rowIndex) => (
                            <tr key={rowIndex}>
                                {[...Array(6)].map((_, colIndex) => (
                                    <td
                                        key={colIndex}
                                        className="td-cash "
                                    >
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <AddMeeting isOpen={addmeeting} onClose={handleCloseMeeting} />
        </div>
    )
}

export default MOM