import React from 'react'

const StoreApproved = () => {
    return (
        <div className="mt-6">
            <h1 className="list-heading mb-4">Approved Purchase Register</h1>
            <div className="overflow-x-auto">
                <table className="table-in-cashflow">
                    <thead>
                        <tr className="bg-[#78222E] text-white">
                            <th className="list-th-common ">Requisition No.</th>
                            <th className="list-th-common ">Description</th>
                            <th className="list-th-common ">Type</th>
                            <th className="list-th-common ">Quantity</th>
                            <th className="list-th-common ">Date of Approval</th>
                            <th className="list-th-common">
                                Approved By
                            </th>
                            <th className="list-th-common">Quotation</th>
                            <th className="list-th-common">Reference wo (5)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="bg-gray-100">
                            <td className="tbody-td-cash text-center">Add New item</td>
                            <td className="tbody-td-cash"></td>
                            <td className="tbody-td-cash"></td>
                            <td className="tbody-td-cash"></td>
                            <td className="tbody-td-cash"></td>
                            <td className="tbody-td-cash "></td>
                            <td className="tbody-td-cash text-center">Option</td>
                            <td className="tbody-td-cash text-center">Optional</td>
                        </tr>
                        {[...Array(4)].map((_, rowIndex) => (
                            <tr key={rowIndex}>
                                {[...Array(8)].map((_, colIndex) => (
                                    <td
                                        key={colIndex}
                                        className="td-cash"
                                    ></td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default StoreApproved