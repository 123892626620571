import React from 'react'

const Labour = () => {
  return (
    <div>    <div className="list-header">
    <h1 className="list-heading">Labour</h1>

  </div>  
<div className=" shadow-md">
<div className="overflow-x-auto">
    <table className="table-in-cashflow">
        <thead>
            <tr className="bg-[#78222E] text-white">
                <th className="list-th-common ">Date</th>
                <th className="list-th-common ">Person Name</th>
                <th className="list-th-common ">Company Name</th>
                <th className="list-th-common ">Aadhar No. </th>
                <th className="list-th-common ">Pan No.</th>

            </tr>
        </thead>
        <tbody>
            {[...Array(10)].map((_, rowIndex) => (
                <tr key={rowIndex}>
                    {[...Array(5)].map((_, colIndex) => (
                        <td
                            key={colIndex}
                            className="td-cash"
                        ></td>
                    ))}
                </tr>
            ))}
        </tbody>
    </table>
</div>
</div></div>
  )
}

export default Labour