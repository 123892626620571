import React from 'react'
import ManPower from '../../procurementPlan/security/ManPower'

const DebitNoteManpower = () => {
  return (
    <div className=''>
        <ManPower />
    </div>
  )
}

export default DebitNoteManpower