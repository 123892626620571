import React from 'react'
import { Icons } from '../../../icons'

const AddWorkMethodlogy = ({isOpen,onClose}) => {
    if(!isOpen){
        return null
    }
  return (
    <div className="add-main">
    <div className="add-popup ">
      {/* Popup Header */}
      <div className="add-pop-header">
        <h2 className="add-heading">Add Work Methodology</h2>
        <span onClick={onClose} className=" cursor-pointer"><Icons.cross size={20} /></span>
      </div>
      <hr className="mt-5 mx-5" />
      {/* Form */}
      <form className="p-6">
        {/* Row 1 */}
        <div className="add-grid-container">
          <div>
            <label className="add-lable">
            Checked By
            </label>
            <input
              type="text"
              className="add-input"
              placeholder=" "
            />
          </div>
          <div>
            <label className="add-lable">
            Approved By		
            </label>
            <input
              type="text"
              className="add-input"
              placeholder=" "
            />
          </div>
         
        </div>

        {/* Row 2 */}
        <div className="add-grid-container">
          <div>
              <label className="add-lable">
              Attach Files
              </label>
              <input
              type="file"
              className="add-input"
              placeholder=""
            />
          </div>
          <div>
            <label className="add-lable">
            Remarks
            </label>
            <textarea
              type="text"
              className="add-input"
              placeholder=""
            />
          </div>

        </div>


        {/* Buttons */}
        <div className="add-btn-container">
          <button
            type="button"
            onClick={onClose}
            className="in-add-cancel-btn"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="in-add-confirm-btn"
          >
            Confirm
          </button>
        </div>
      </form>
    </div>
  </div>
  )
}

export default AddWorkMethodlogy