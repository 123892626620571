import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import QualityTab from './QualityTab'
import ChecklistTab from './ChecklistTab'
import { Icons } from '../../../../icons';
import CreateCheckList from './CreateCheckList';
import AddItem from './AddItem';

const QualityHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('Quality');
  const [CreateCheckListModal, setCreateCheckListModal] = useState(false)
  const [addItem, setAddItem] = useState(false)
  const projects = ["Project A", "Project B", "Project C", "Project D"];

  const handleCheckList = () =>{
    setCreateCheckListModal(!CreateCheckListModal)
  }

  const handleclosechecklist = () =>{
    setCreateCheckListModal(false)
  }

  // AddItem

  const handleItemModal = () =>{
    setAddItem(!addItem)
  }

  const handlecloseItem =() =>{
    setAddItem(false)
  }

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div>
      {/* Header */}
      <div className="list-header">
        <div className="list-tab-btn">
          <span
            onClick={() => handleTabClick('Quality')}
            className={` ${activeTab === 'Quality' ? "bg-gray-500" : ""}  px-8 py-1 rounded-md`}
            role="button" aria-pressed={activeTab === 'Quality'}
          >
           12 Quality
          </span>
          <span
            onClick={() => handleTabClick('Checklist')}
            className={` ${activeTab === 'Checklist' ? "bg-gray-500" : ""}  px-8 py-1 rounded-md`}
            role="button" aria-pressed={activeTab === 'Checklist'}
          >
          12 Checklist
          </span>
        </div>
        {activeTab ==='Quality' ? (
        <div className="flex gap-4">
         
          <div className="dropdown-relative">
            {/* Button */}
            <button
              className="dropdown-container-btn"
              onClick={() => setIsOpen(!isOpen)}
              aria-expanded={isOpen}
              aria-haspopup="true"
            >
              <span>Select Project</span>
              <span>
                {/* Replace with your Icons.arrowdropdown */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-5 w-5 transform ${isOpen ? "rotate-180" : ""}`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </span>
            </button>

            {/* Dropdown Menu */}
            {isOpen && (
              <div className="dropdown-menu-container">
                <ul className="">
                  {projects.map((project, index) => (
                    <li
                      key={index} 
                      onClick={() => {
                        setIsOpen(false);
                        alert(`Selected: ${project}`);
                      }}
                    >
                      {project}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <button onClick={handleItemModal}  className={`list-add-btn`}>
            Add Item <Icons.add size={20}/>
          </button>
        </div> 
        ) : (
          <div className="flex gap-4">
         
          <div className="dropdown-relative">
            {/* Button */}
            <button
              className="dropdown-container-btn"
              onClick={() => setIsOpen(!isOpen)}
              aria-expanded={isOpen}
              aria-haspopup="true"
            >
              <span>Select Check List</span>
              <span>
                {/* Replace with your Icons.arrowdropdown */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-5 w-5 transform ${isOpen ? "rotate-180" : ""}`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </span>
            </button>

            {/* Dropdown Menu */}
            {isOpen && (
              <div className="dropdown-menu-container">
                <ul className="">
                  {projects.map((project, index) => (
                    <li
                      key={index} 
                      onClick={() => {
                        setIsOpen(false);
                        alert(`Selected: ${project}`);
                      }}
                    >
                      {project}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <button onClick={handleCheckList} className={`list-add-btn`}>
            Create New Check List <Icons.add size={20}/>
          </button>
        </div> 
        )}
      </div>

      {/* Tabs render */}
      <div className="mt-4">
        {activeTab === 'Quality' && <QualityTab />}
        {activeTab === 'Checklist' && <ChecklistTab />}
      </div>
        <CreateCheckList isOpen={CreateCheckListModal} onClose={handleclosechecklist} />
        <AddItem isOpen={addItem} onClose={handlecloseItem} />
    </div>
  )
}

export default QualityHeader;
