import React from "react";

const WorkProgressTab = () => {
    return (
        <div className=" shadow-md">
            <div className="overflow-x-auto">
                <table className="table-in-cashflow">
                    <thead>
                        <tr className="bg-[#78222E] text-white">
                        <th className="list-th-common">Activity No.</th>

                            <th className="list-th-common">Activity Name</th>
                            <th className="list-th-common">Planned Start Date</th>
                            <th className="list-th-common">Actual Start Date</th>
                            <th className="list-th-common">Revise Milestane Date</th>
                            {/* <th className="list-th-common">Executed Qty From DPR</th> */}
                            <th className="border border-gray-300 p-2">
                                Delay
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="bg-gray-100">
                            <td className="tbody-td-cash"></td>
                            <td className="tbody-td-cash"></td>

                            <td className="tbody-td-cash text-center">(Qty/Day)</td>
                            <td className="tbody-td-cash text-center">18</td>
                            <td className="tbody-td-cash text-center">(=8c-E)</td>
                            {/* <td className="tbody-td-cash text-center">(=(F/8D)+Current Date)</td> */}
                            <td className="tbody-td-cash text-center">(=D-G)</td>


                        </tr>
                        {[...Array(10)].map((_, rowIndex) => (
                            <tr key={rowIndex}>
                                {[...Array(6)].map((_, colIndex) => (
                                    <td
                                        key={colIndex}
                                        className="td-cash"
                                    ></td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default WorkProgressTab;
