import React from 'react'
import StoreHead from './StoreHead'
import StoreApproved from './StoreApproved'
import StockInward from './StockInward'
import MaterialRequisition from './MaterialRequisition'

const Store = () => {
  return (
    <div>
      <StoreHead />
      <MaterialRequisition />
      <StoreApproved />
     
    </div>
  )
}

export default Store