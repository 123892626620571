import React, { useState } from 'react'
import { Icons } from '../../../../icons'

const Manpower = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div className="overflow-x-auto">
                <h1 className="list-heading">Manpower</h1>
                <br />
                <table className="table-in-cashflow">
                    <thead>
                        <tr className="bg-[#78222E] text-white">
                            <th className="list-th-common ">Date</th>
                            <th className="list-th-common ">Name of Person</th>
                            <th className="list-th-common ">Aadhar Card</th>
                            <th className="list-th-common relative">
                                <span className="dropdown-table-btn flex justify-center items-center" onClick={toggleDropdown}>
                                    Company Name{" "}
                                    {isOpen ? (
                                        <Icons.arrowclose size={24} />
                                    ) : (
                                        <Icons.arroopen size={24} />
                                    )}
                                </span>
                                {isOpen && (
                                    <ul className="dropdown-table-menu-head">
                                        <li className="dropdown-table-menu-text">Take picture</li>
                                    </ul>
                                )}
                            </th>
                            <th className="list-th-common relative">
                                <span className="dropdown-table-btn flex justify-center items-center" onClick={toggleDropdown}>
                                    Incident report{" "}
                                    {isOpen ? (
                                        <Icons.arrowclose size={24} />
                                    ) : (
                                        <Icons.arroopen size={24} />
                                    )}
                                </span>
                                {isOpen && (
                                    <ul className="dropdown-table-menu-head">
                                        <li className="dropdown-table-menu-text">Take picture</li>
                                    </ul>
                                )}
                            </th>
                            {/* Add number of person in same compny to total */}

                           </tr>
                    </thead>
                    <tbody>
                        {[...Array(5)].map((_, rowIndex) => (
                            <tr key={rowIndex}>
                                {[...Array(5)].map((_, colIndex) => (
                                    <td
                                        key={colIndex}
                                        className="td-cash"
                                    >
                                        
                                    </td>
            
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
                <h1 className='py-2 font-bold text-center border'>Add number of person in same compny to total</h1>

            </div>
  )
}

export default Manpower