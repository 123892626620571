import React from 'react'
import { Icons } from '../../../../icons'

const AddItem = ({ isOpen, onClose }) => {
    if (!isOpen) {
        return null
    }
    return (
        <div className="add-main">
            <div className="add-popup scrollbar-hide">
                {/* Popup Header */}
                <div className="add-pop-header">
                    <h2 className="add-heading">Add Item</h2>
                    <span onClick={onClose} className=" cursor-pointer"><Icons.cross size={20} /></span>
                </div>
                <hr className="mt-5 mx-5" />
                {/* Form */}
                <form className="p-6">
                    {/* Row 1 */}
                    <div className="add-grid-container">
                        <div>
                            <label className="add-lable">
                                Item
                            </label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="Enter Item"
                            />
                        </div>
                        <div>
                            <label className="add-lable">
                                Item Name
                            </label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="item name"
                            />
                        </div>
                    </div>

                    <div className="add-grid-container">
                        <div>
                            <label className="add-lable">
                                Address line 1
                            </label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="Address line 1"
                            />
                        </div>
                        <div>
                            <label className="add-lable">
                                Address line 2
                            </label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="Address line 2"
                            />
                        </div>


                    </div>

                    <div className="add-grid-container">
                        <div>
                            <label className="add-lable">
                                State
                            </label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="State"
                            />
                        </div>
                        <div>
                            <label className="add-lable">
                                City
                            </label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="City"
                            />
                        </div>


                    </div>

                    {/* Row 2 */}
                    <div className="add-grid-container">
                        <div>
                            <label className="add-lable">
                                Pincode
                            </label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="Pincode"
                            />
                        </div>
                        <div>
                            <label className="add-lable">
                                Area
                            </label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="area"
                            />
                        </div>


                    </div>

                    {/* Row 3 */}
                    <div className="add-grid-container">
                        <div>
                            <label className="add-lable">
                                Identification Number
                            </label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="identification no."
                            />
                        </div>
                        <div>
                            <label className="add-lable">
                                Required
                            </label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="required"
                            />
                        </div>




                    </div>
                    {/* Row 4 */}
                    <div className="add-grid-container">
                        <div>
                            <label className="add-lable">
                                Test Result
                            </label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="test result"
                            />
                        </div>
                        <div>
                            <label className="add-lable">
                                Unit
                            </label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="unit"
                            />
                        </div>
                        <div>
                            <label className="add-lable">
                                Status
                            </label>
                            <select
                                className="add-input bg-white"
                                defaultValue=""
                            >
                                <option value="" disabled>
                                    Select
                                </option>
                                <option value="new-york">Option 1</option>
                                <option value="los-angeles">Option 2</option>
                                <option value="chicago">Option 3</option>
                                <option value="houston">Option 4</option>
                                <option value="miami">Option 5</option>
                            </select>
                        </div>
                        <div>
                            <label className="add-lable">
                                Upload
                            </label>
                            <input
                                type="file"
                                className="add-input"
                                accept="image/*"
                            />
                        </div>
                    </div>

                    {/* Buttons */}
                    <div className="add-btn-container">
                        <button
                            type="button"
                            onClick={onClose}
                            className="in-add-cancel-btn"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="in-add-confirm-btn"
                        >
                            Confirm
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddItem