import React, { useState } from 'react'
import { Icons } from '../../../../icons';
import AddProject from './AddProject';
import { useLocation } from 'react-router-dom';

const ProcurementPlanHead = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const { pathname } = useLocation()
    console.log(pathname)
    const [addProjectModal, setAddProjectModal] = useState(false)

    const handleAddProjectModal = () => {
        setAddProjectModal(!addProjectModal)
    }

    const handleclose = () => {
        setAddProjectModal(false)
    }

    const projects = ["Project A", "Project B", "Project C", "Project D"];
    const accessusers = ["users A", "users B", "users C", "users D"];
    return (
        <div>    {/* Header */}
            <div className="list-header">
                {pathname === "/dashboard/procurement-plan" ? (
                    <h1 className="list-heading">4 Procurement Plan</h1>
                ) : (
                    <h1 className="list-heading">Summary of Procurement Plan</h1>
                )}
                <div className=' flex gap-4 flex-wrap justify-center items-center'>
                    {/* Select Project Dropdwon */}
                    {pathname.includes("/dashboard/procurement-summery") ||  
                    <div className="dropdown-relative">
                        {/* Button */}
                        <button
                            className="dropdown-container-btn"
                            onClick={() => setIsOpen1(!isOpen1)}
                        >
                            <span>Access to users</span>
                            <span>
                                {/* Replace with your Icons.arrowdropdown */}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className={`h-5 w-5 transform ${isOpen1 ? "rotate-180" : ""}`}
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M19 9l-7 7-7-7"
                                    />
                                </svg>
                            </span>
                        </button>

                        {/* Dropdown Menu */}
                        {isOpen1 && (
                            <div className="dropdown-menu-container">
                                <ul className="">
                                    {accessusers.map((project, index) => (
                                        <li
                                            key={index}
                                            className=""
                                            onClick={() => {
                                                setIsOpen(false);
                                                alert(`Selected: ${project}`);
                                            }}
                                        >
                                            {project}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                    }
                    <div className="dropdown-relative">
                        {/* Button */}
                        <button
                            className="dropdown-container-btn"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <span>Select Project</span>
                            <span>
                                {/* Replace with your Icons.arrowdropdown */}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className={`h-5 w-5 transform ${isOpen ? "rotate-180" : ""}`}
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M19 9l-7 7-7-7"
                                    />
                                </svg>
                            </span>
                        </button>

                        {/* Dropdown Menu */}
                        {isOpen && (
                            <div className="dropdown-menu-container">
                                <ul className="">
                                    {projects.map((project, index) => (
                                        <li
                                            key={index}
                                            className=""
                                            onClick={() => {
                                                setIsOpen(false);
                                                alert(`Selected: ${project}`);
                                            }}
                                        >
                                            {project}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                    {pathname.includes("/dashboard/procurement-summery") ||
                    <button style={{width:'200px',height:'40px'}} onClick={handleAddProjectModal} className="list-add-btn">
                        <span>Add New Item</span> <span><Icons.add size={20} /></span>
                    </button>
}
                </div>
            </div>
            <AddProject isOpen={addProjectModal} onClose={handleclose} />

        </div>
    )
}

export default ProcurementPlanHead