import React, { useState } from "react";
import { Icons } from "../../../../icons";
import Select from "react-select"; // Correct import for react-select

const AddCompany = ({ isOpen, isClose }) => {
    const options = [
        { value: "Abc", label: "ab" },
        { value: "Xyz", label: "xy" },
        { value: "mn", label: "mn" },
        { value: "svelte", label: "Svelte" },
        { value: "svelte", label: "Svelte" },


        { value: "svelte", label: "Svelte" },

    ];

    // State to handle selected option
    const [selectedOption, setSelectedOption] = useState(null);

    // Function to handle change
    const handleChange = (option) => {
        setSelectedOption(option);
        console.log("Selected:", option);
    };

    if (!isOpen) {
        return null;
    }

   // Custom styles for the Select component
   const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? "1px solid #78222E17" : "1px solid #ccc", // Change border color on focus
      outline: state.isFocused ? "1px solid #78222E17" : "none", // Change outline color on focus
      boxShadow: state.isFocused ? "#78222E17" : "none", // Box-shadow on focus
      transition: "border-color 0.2s ease, box-shadow 0.2s ease", // Smooth transition for border and shadow
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#78222E17" : "#fff", // Background color for focused option
      color: state.isFocused ? "#78222E" : "#000", // Color for focused option
    }),
    menu: (provided) => ({
      ...provided,
      maxHeight: "200px", // Set max height for the dropdown
      overflowY: "auto", // Enable vertical scrolling
      borderRadius: "0px", // Optional: remove border radius if needed
    }),
    menuList: (provided) => ({
      ...provided,
      padding: "0", // Remove any default padding
    }),
  };
  
  
  
    return (
        <div className="add-main">
            <div className="add-popup">
                {/* Popup Header */}
                <div className="add-pop-header">
                    <h2 className="add-heading">Add Company</h2>
                    <span onClick={isClose} className="cursor-pointer">
                        <Icons.cross size={20} />
                    </span>
                </div>

                {/* Form */}
                <form className="p-6">
                    {/* Row 1 */}
                    <div className="add-grid-container">
                        <div>
                            <label className="add-lable">Company Name</label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="Enter project name"
                            />
                        </div>
                        <div>
                            <label className="add-lable">Address line 1</label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="Address line 1"
                            />
                        </div>
                    </div>

                    {/* Row 2 */}
                    <div className="add-grid-container">
                        <div>
                            <label className="add-lable">Address line 2</label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="Address line 2"
                            />
                        </div>
                        <div>
                            <label className="add-lable">State</label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="Enter State"
                            />
                        </div>
                    </div>

                    {/* Row 3 */}
                    <div className="add-grid-container">
                        <div>
                            <label className="add-lable">City</label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="City"
                            />
                        </div>
                        <div>
                            <label className="add-lable">Pincode</label>
                            <input
                                type="number"
                                className="add-input"
                                placeholder="Pincode"
                            />
                        </div>
                    </div>

                    {/* Row 4 */}
                    <div className="add-grid-container">
                        <div>
                            <label className="add-lable">PAN No</label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="PAN No"
                            />
                        </div>
                        <div>
                            <label className="add-lable">GST No</label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="GST No"
                            />
                        </div>
                    </div>

                    {/* Row 5 */}
                    <div className="add-grid-container">
                        <div>
                            <label className="add-lable">PAN/GST</label>
                            <input
                                type="file"
                                className="add-input"
                                accept="image/*"
                            />
                        </div>
                        <div>
                            <label className="add-lable">Company Profile</label>
                            <Select
                              
                                options={options} // Options for the dropdown
                                value={selectedOption} // Controlled selected value
                                onChange={handleChange} // Callback on selection change
                                isClearable // Add a clear button
                                styles={customStyles} // Apply custom styles here

                                placeholder="Select company profile..." // Placeholder text
                            />
                        </div>
                    </div>

                    {/* Row 6 */}
                    <div className="add-grid-container">
                        <div>
                            <label className="add-lable">Upload logo</label>
                            <input
                                type="file"
                                className="add-input"
                                accept="image/*"
                            />
                        </div>
                        <div>
                            <label className="add-lable">Contact Number</label>
                            <input
                                type="number"
                                className="add-input"
                                placeholder="Enter Number"
                            />
                        </div>
                        <div>
                            <label className="add-lable">Contact Person
                            </label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="Enter Name"
                            />
                        </div>
                    </div>

                    {/* Buttons */}
                    <div className="add-btn-container">
                        <button
                            type="button"
                            onClick={isClose}
                            className="in-add-cancel-btn"
                        >
                            Cancel
                        </button>
                        <button type="submit" className="in-add-confirm-btn">
                            Confirm
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddCompany;
