import React, { useState } from 'react'
import ProcurementPlanHead from '../procurement/ProcurementPlanHead'
import ProcurementPlanList from '../procurement/ProcurementPlanList'
import ProcurementTablesecond from './ProcurementTablesecond'

const ProcurementPlan = () => {

  return (
    <div>
      <ProcurementPlanHead />
      <ProcurementPlanList />
       <ProcurementTablesecond />
    </div>
  )
}

export default ProcurementPlan