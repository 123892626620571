import React from 'react'
import Manpower from '../../help/supportfeedback/Manpower'

const SupportFeedBack = () => {
  return (
    <div>
      <div className="overflow-x-auto mb-6">
        <h1 className="list-heading">Manpower</h1>
        <br />
        <table className="table-in-cashflow text-center">
          <thead>
            <tr className="bg-[#78222E] text-white">
              <th className="list-th-common">Subject</th>
              <th className="list-th-common">Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="list-th-common">Raise ticket number</td>
              <td className="list-th-common">The process of finding and hiring qualified candidates for job openings.</td>
            </tr>
            <tr>
              <td className="list-th-common">Follow-up procedure</td>
              <td className="list-th-common">The process of finding and hiring qualified candidates for job openings.</td>

            </tr>
            <tr>
              <td className="list-th-common">closing tickets</td>
              <td className="list-th-common">The process of finding and hiring qualified candidates for job openings.</td>

            </tr>
           
          </tbody>
        </table>
      </div>

      <Manpower />
    </div>
  )
}

export default SupportFeedBack