import React from "react";

const CashFlow = () => {
  return (
    <div className="">
      <h1 className="cashflow-heading">5A Cash Flow</h1>
      <div className="overflow-x-auto">
        <table className="table-in-cashflow">
          <thead>
            <tr className="bg-[#78222E] text-white">
              <th  className="list-th-common">Package Name</th>
              <th className="list-th-common">Type</th>
              <th className="list-th-common">Start Date</th>
              <th className="list-th-common">Duration</th>
              <th className="list-th-common">Total Amount</th>
              <th colSpan="12" className="list-th-common">
                Month/Year
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-gray-100">
              <td className="tbody-td-cash"></td>
              <td className="tbody-td-cash"></td>
              <td className="tbody-td-cash">Enter Start Date</td>
              <td className="tbody-td-cash">Enter Duration In Months</td>
              <td className="tbody-td-cash">Total Amount</td>
              {[...Array(12)].map((_, index) => (
                <td
                  key={index}
                  className="year-month-row text-center"
                >
                  {index + 1}
                </td>
              ))}
            </tr>
            {[...Array(10)].map((_, rowIndex) => (
              <tr key={rowIndex}>
                {[...Array(17)].map((_, colIndex) => (
                  <td
                    key={colIndex}
                    className="td-cash"
                  ></td>
                ))}
              </tr>
            ))}
            <tr className="bg-yellow-400">
              <td className="year-month-row" colSpan="2">
                Month/Year
              </td>
            </tr>
            <tr className="bg-yellow-400">
              <td className="year-month-row" colSpan="2">
                Total
              </td>
            </tr>
            <tr className="bg-green-400">
              <td className="year-month-row" colSpan="2">
                Month/Year
              </td>
            </tr>
            <tr className="bg-green-400">
              <td className="year-month-row" colSpan="2">
                Total
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CashFlow;
