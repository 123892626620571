import React, { useState } from 'react'
import { Icons } from '../../../icons'
import { useNavigate } from 'react-router-dom'
import Select from "react-select"; // Correct import for react-select


const ProjectForm = () => {
  const options = [
    { value: "Abc", label: "ab" },
    { value: "Xyz", label: "xy" },
    { value: "mn", label: "mn" },
    { value: "svelte", label: "Svelte" },
    { value: "svelte", label: "Svelte" },


    { value: "svelte", label: "Svelte" },

  ];

  // State to handle selected option
  const [selectedOption, setSelectedOption] = useState(null);

  // Function to handle change
  const handleChange = (option) => {
    setSelectedOption(option);
    console.log("Selected:", option);
  };
  const navigate = useNavigate()

  // Custom styles for the Select component
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? "" : "", // Change border color on focus
      outline:"none", // Change outline color on focus
      boxShadow: state.isFocused ? "#78222E17" : "none", // Box-shadow on focus
      transition: "border-color 0.2s ease, box-shadow 0.2s ease", // Smooth transition for border and shadow
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#78222E17" : "#fff", // Background color for focused option
      color: state.isFocused ? "#78222E" : "#000", // Color for focused option
    }),
    menu: (provided) => ({
      ...provided,
      maxHeight: "200px", // Set max height for the dropdown
      overflowY: "auto", // Enable vertical scrolling
      borderRadius: "0px", // Optional: remove border radius if needed
    }),
    menuList: (provided) => ({
      ...provided,
      padding: "0", // Remove any default padding
    }),
  };
  return (
    <div>

      <div className="p-4 mb-4 bg-white rounded-md shadow-md w-full">
        <h1 className='font-bold pb-4 flex gap-4'> <Icons.arrowleft size={20} onClick={() => navigate('/dashboard/project-task')} className='mt-[2px] cursor-pointer' />  Mail</h1>
        <div className="sm:flex flex-wrap sm:space-y-0 space-y-2 items-start">
          {/* Send Button */}
          <button className="flex-shrink-0 p-3 bg-white border border-gray-300 rounded-lg flex flex-col items-center justify-center gap-2 hover:bg-gray-100">
            <Icons.sendarrow size={20} />

            <span className="text-sm">Send</span>
          </button>

          {/* Form */}
          <form className="flex-grow sm:ml-4">
            <div className="">
              {/* Input Fields with Bordered Container */}
              {[
                { label: "From", placeholder: "" },
                { label: "To...", placeholder: " " },
                { label: "Cc...", placeholder: "" },
                { label: "Subject", placeholder: "" },
                { label: "Document Type", placeholder: "" },
                { label: "Due Date", placeholder: "" },
                {
                  label: "Document Number",
                  placeholder: "PROJ/ABC-XYZ/A/0001",
                  defaultValue: "PROJ/ABC-XYZ/A/0001",
                },
                {
                  label: "Reference",
                  placeholder: "Link Document Number",
                },
              ].map((field, index) => (
                <div
                  key={index}
                  className="sm:flex sm:space-y-0 space-y-4 items-center rounded-md sm:p-2 pt-2"
                >

                  {/* Label */}
                  <label
                    htmlFor={field.label.toLowerCase().replace(" ", "-")}
                    className="text-sm font-medium w-44 mr-4 text-gray-700 rounded-lg p-[10px] border border-gray-300"
                  >{field.label}
                    {field.label === 'Form' && (<span><Icons.arrow /></span>)}
                  </label>
                  {/* Input */}
                  {/* Input */}
                  {field.label === 'Document Type' ? (
                    <div className="w-full p-2 rounded-lg border border-gray-300 outline-none">
                      <Select
                        options={options} // Options for the dropdown
                        value={selectedOption} // Controlled selected value
                        onChange={handleChange} // Callback on selection change
                        isClearable // Add a clear button
                        styles={customStyles} // Apply custom styles here
                        placeholder="Select Document Type..." // Placeholder text
                      />
                    </div>
                  ) : (
                    <input
                      id={field.label.toLowerCase().replace(" ", "-")}
                      type="text"
                      placeholder={field.placeholder}
                      defaultValue={field.defaultValue || ""}
                      className="w-full p-2 rounded-lg border border-gray-300 outline-none"
                    />
                  )}

                </div>
              ))}
            </div>

            {/* Large Textarea */}
            <div className="mt-4 border border-gray-300 rounded-md p-2">
              <textarea
                id="textarea"
                rows="4"
                className="w-full mt-1 p-2 outline-none"
                placeholder=" "
              ></textarea>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ProjectForm