import React, { useState } from 'react'
import { Icons } from '../../../../icons'
import AddNew from './AddNew'

const NewPlantMachinery = () => {
          const [StockModal, setStockModal] = useState(false)
        
          const handleStockPopUp = () => {
            setStockModal(!StockModal)
          }
        
          const handleStockPopUpClose = () => {
            setStockModal(false)
          }
  return (
    <div>   {/* Header */}
    <div className="list-header">
      <h1 className="list-heading"></h1>
      <div className=' flex gap-4'>
        <button onClick={handleStockPopUp} className="list-add-btn">
          <span>Add New</span> <span><Icons.add size={20} /></span>
        </button>
      </div>
    </div>
    <div className="overflow-x-auto">
  <table className="table-in-cashflow">
      <thead>
          <tr className="bg-[#78222E] text-white">
              <th className="list-th-common ">Sr No.</th>
              <th className="list-th-common ">Name</th>
              <th className="list-th-common ">Company Name</th>
              <th className="list-th-common ">Date</th>
              <th className="list-th-common ">Start Date</th>
              <th className="list-th-common ">End Date</th>
              <th className="list-th-common ">Total Cost</th>

            
          </tr>
      </thead>
    
      <tbody>
        
          {[...Array(4)].map((_, rowIndex) => (
              <tr key={rowIndex}>
                  {[...Array(7)].map((_, colIndex) => (
                      <td
                          key={colIndex}
                          className="td-cash"
                      ></td>
                  ))}
              </tr>
          ))}
      </tbody>
  </table>
</div>
    <AddNew isOpen={StockModal} onClose={handleStockPopUpClose} />

    </div>
  )
}

export default NewPlantMachinery