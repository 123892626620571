import React from 'react'
import { Icons } from '../../../icons'
import { useNavigate } from 'react-router-dom'

const ProjectTable = () => {
  const navigate = useNavigate()
  return (
    <div>   
            {/* Header */}
            <div className="list-header">
        <h1 className="list-heading">7 Project Communication</h1>
        <button onClick={() =>navigate('/dashboard/project-mail')} className="list-add-btn">
         <span>Send Mail</span>{' '}<span><Icons.sendarrow size={20} /></span>
        </button>
      </div>
        {/* Table */}
    <div className="list-table-head">
      <table className="w-full text-left border-collapse ">
        <thead className=''>
          <tr className="list-table-container">
            <th className="list-th">Form Company</th>
            <th className="list-th">By Name</th>
            <th className="list-th">Document Type</th>
            <th className="list-th">Subject</th>
            <th className="list-th">Due Date</th>
            <th className="list-th">Due By</th>
          </tr>
        </thead>
        <tbody>
          {/* Example Row */}
          <tr className="border-t text-gray-600 ">
            <td className="list-th">Sample Project</td>
            <td className="list-th">hello</td>
            <td className="list-th">Type</td>
            <td className="list-th">Subject</td>
            <td className="list-th">26/11/2024</td> 
            <td className="list-th">30/11/2024</td> 

          </tr>
        </tbody>
      </table>
    </div>
    </div>
  )
}

export default ProjectTable