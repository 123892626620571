import React from 'react'
import { Icons } from '../../../icons'

const AddBills = ({ isOpen, onClose }) => {
    if (!isOpen) {
        return null
    }
    return (
        <div className="add-main">
            <div className="add-popup ">
                {/* Popup Header */}
                <div className="add-pop-header">
                    <h2 className="add-heading">Add Bill</h2>
                    <span onClick={onClose} className=" cursor-pointer"><Icons.cross size={20} /></span>
                </div>
                <hr className="mt-5 mx-5" />
                {/* Form */}
                <form className="p-6">
                    {/* Row 1 */}
                    <div className="add-grid-container">
                        <div>
                            <label className="add-lable">
                                Company Name
                            </label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="Company Name"
                            />
                        </div>
                        <div>
                            <label className="add-lable">
                                Type
                            </label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="Type"
                            />
                        </div>

                    </div>

                    {/* Row 2 */}
                    <div className="add-grid-container">
                    <div>
                            <label className="add-lable">
                            Quotation
                            </label>
                            <select
                                className="add-input bg-white"
                                defaultValue=""
                            >
                                <option value="" disabled>
                                    Select
                                </option>

                            </select>
                        </div>
                        <div>
                            <label className="add-lable">
                            Challan
                            </label>
                            <select
                                className="add-input bg-white"
                                defaultValue=""
                            >
                                <option value="" disabled>
                                    Select
                                </option>
                                <option value="new-york">Challan</option>
                                <option value="los-angeles">Measurement Sheet</option>
                                <option value="new-york">Attach File</option>

                            </select>
                        </div>
                        <div>
                            <label className="add-lable">
                            Reconciliation
                            </label>
                            <select
                                className="add-input bg-white"
                                defaultValue=""
                            >
                                <option value="" disabled>
                                    Select
                                </option>

                            </select>
                        </div>
                        <div>
                            <label className="add-lable">	
                            Due Date
                            </label>
                            <input
                                type="date"
                                className="add-input"
                                placeholder=""
                            />
                        </div>
                        <div>
                            <label className="add-lable">
                            Certified By
                            </label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="Certified By"
                            />
                        </div>
                        <div>
                            <label className="add-lable">
                            Certified Amount	
                            </label>
                            <input
                                type="number"
                                className="add-input"
                                placeholder=" "
                            />
                        </div>
                        <div>
                            <label className="add-lable">
                            Status
                            </label>
                            <select
                                className="add-input bg-white"
                                defaultValue=""
                            >
                                <option value="" disabled>
                                    Select
                                </option>
                                <option value="paid" disabled>
                                    Paid
                                </option><option value="unpaid" disabled>
                                    Unpaid
                                </option>
                            </select>
                        </div>
                        <div>
                            <label className="add-lable">
                            Balance	
                            </label>
                            <input
                                type="number"
                                className="add-input"
                                placeholder=" "
                            />
                        </div>
                        <div>
                            <label className="add-lable">
                            Balance Due		
                            </label>
                            <input
                                type="number"
                                className="add-input"
                                placeholder=" "
                            />
                        </div>
                        <div>
                            <label className="add-lable">
                            Attach File	
                            </label>
                            <input
                                type="file"
                                className="add-input"
                                placeholder=" "
                            />
                        </div>
                    </div>

                    {/* Buttons */}
                    <div className="add-btn-container">
                        <button
                            type="button"
                            onClick={onClose}
                            className="in-add-cancel-btn"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="in-add-confirm-btn"
                        >
                            Confirm
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddBills