import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { Icons } from '../../../../icons';
import AddVendore from './AddVendore';

const VendorManagement = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [vendorModal, setVendorModal] = useState(false)
    const projects = ["Project A", "Project B", "Project C", "Project D"];

    const handleVendorModal = () =>{
      setVendorModal(!vendorModal)
    }

    const handleClose =()=>{
      setVendorModal(false)
    }
  
  return (
    <div>
     {/* Header */}
    <div className="list-header">
      <h1 className="list-heading">19 Vendore Management</h1>
      <div className=' flex gap-4'>
        <div className="dropdown-relative">
          {/* Button */}
          <button
            className="dropdown-container-btn"
            onClick={() => setIsOpen(!isOpen)}
          >
            <span>Global Vendor list</span>
            <span>
              {/* Replace with your Icons.arrowdropdown */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`h-5 w-5 transform ${isOpen ? "rotate-180" : ""}`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </span>
          </button>

          {/* Dropdown Menu */}
          {isOpen && (
            <div className="dropdown-menu-container">
              <ul className="">
                {projects.map((project, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      setIsOpen(false);
                      alert(`Selected: ${project}`);
                    }}
                  >
                    {project}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <button onClick={handleVendorModal} className="list-add-btn">
          Vendor<Icons.add />
        </button>
      </div>
    </div>
       {/* Table */}
       <div className="list-table-head">
        <table className="list-table ">
          <thead className=''>
            <tr className="list-table-container">
              <th className="list-th">Comapny Name</th>
              <th className="list-th">Profile</th>
              <th className="list-th">Location</th>
              <th className="list-th">Contact Number</th>
              <th className="list-th">Logo</th>
              <th className="list-th">Action</th>
            </tr>
          </thead>
          <tbody>
            {/* Example Row */}
            <tr className="list-tr">
              <td className="list-th">Sample Project</td>
              <td className="list-th">Global</td>
              <td className="list-th">sliconcity</td>
              <td className="list-th">9383736383</td>
              <td className="list-th ">
              <img
                  src="https://via.placeholder.com/50"
                  alt="Project"
                  className="list-img"
                />
              </td>
              <td className="list-th list-action">
                <Icons.edit size={24} />
                <Icons.read size={24}/>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <AddVendore isOpen={vendorModal} onClose={handleClose} />
    </div>
  )
}

export default VendorManagement