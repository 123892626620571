import React, { useState } from "react";
import { Icons } from "../../../../icons";

const AttendanceBelowTable = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className=" shadow-md">
      <div className="overflow-x-auto">
        <table className="table-in-cashflow">
          <thead>
            <tr className="bg-[#78222E] text-white">
              <th className="list-th-common ">Date</th>
              <th className="list-th-common ">Employee Name</th>
              <th className="list-th-common ">In Time</th>
              <th className="list-th-common ">Out Time</th>
              <th className="list-th-common relative">
                <span
                  className="dropdown-table-btn flex justify-center items-center"
                  onClick={toggleDropdown}>
                  Type Labour{" "}
                  {isOpen ? (
                    <Icons.arrowclose size={24} />
                  ) : (
                    <Icons.arroopen size={24} />
                  )}
                </span>
                {isOpen && (
                  <ul className="dropdown-table-menu-head">
                    <li className="dropdown-table-menu-text">Carpenter</li>
                    <li className="dropdown-table-menu-text">Fitter</li>
                    <li className="dropdown-table-menu-text">Engineer</li>
                    <li className="dropdown-table-menu-text">Labour</li>
                    <li className="dropdown-table-menu-text">Plumber</li>
                    <li className="dropdown-table-menu-text">Electrician</li>
                    <li className="dropdown-table-menu-text">Manson</li>

                    <li className="dropdown-table-menu-text">Helper</li>
                  </ul>
                )}
              </th>
              <th className="list-th-common">Company</th>
            </tr>
          </thead>
          <tbody>
            {[...Array(8)].map((_, rowIndex) => (
              <tr key={rowIndex}>
                {[...Array(6)].map((_, colIndex) => (
                  <td key={colIndex} className="td-cash"></td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AttendanceBelowTable;
