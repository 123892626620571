import React, { useState } from 'react'
import { Icons } from '../../../../icons';
import AddTypeModal from './AddTypeModal';
import { useLocation } from 'react-router-dom';

const ManpowerHead = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const [addType, setAddType] = useState(false)
    const location = useLocation()
    const pathname = location.pathname
    console.log(pathname)
    const projects = ["Project A", "Project B", "Project C", "Project D"];

    const handleModalPoPUp = () => {
        setAddType(!addType)
    }

    const handleClose = () => {
        setAddType(false)
    }

    return (
        <>
            {/* Header */}
            <div className="list-header">
                {pathname === '/dashboard/manpower-requirement' ? (
                    <h1 className="list-heading">11 Manpower Requirement</h1>
                ) : pathname === '/dashboard/daily-progress-report' ?

                    <h1 className="list-heading">18 Daily Progress Report</h1>
                    : (

                        <h1 className="list-heading">17 Attendance Report</h1>
                    )
                }
                <div className=' flex gap-4'>
                    <div className="dropdown-relative">
                        {/* Button */}
                        <button
                            className="dropdown-container-btn"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <span>Select Project</span>
                            <span>
                                {/* Replace with your Icons.arrowdropdown */}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className={`h-5 w-5 transform ${isOpen ? "rotate-180" : ""}`}
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M19 9l-7 7-7-7"
                                    />
                                </svg>
                            </span>
                        </button>

                        {/* Dropdown Menu */}
                        {isOpen && (
                            <div className="dropdown-menu-container">
                                <ul className="">
                                    {projects.map((project, index) => (
                                        <li
                                            key={index}
                                            className=""
                                            onClick={() => {
                                                setIsOpen(false);
                                                alert(`Selected: ${project}`);
                                            }}
                                        >
                                            {project}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>

                    {pathname === '/dashboard/daily-progress-report' ? ('') : (
                        <button onClick={handleModalPoPUp} className="list-add-btn">
                            <span>Add Type</span> <span><Icons.add size={20} /></span>
                        </button>
                    )}
                </div>
            </div>
            <AddTypeModal isOpen={addType} onClose={handleClose} />
        </>
    )
}

export default ManpowerHead