import React, { useState } from "react";
import { Icons } from "../../../../icons";
import MainpowerHead from "../Manpower&Requirement/ManpowerHead";
import AttendanceBelowTable from "./AttendanceBelowTable";

const AttendanceReport = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <MainpowerHead />
      <div className=" shadow-md mb-10">
        <div className="overflow-x-auto">
          <table className="table-in-cashflow">
            <thead>
              <tr className="bg-[#78222E] text-white">
                <th className="list-th-common ">Date</th>
                <th className="list-th-common ">Employee Name</th>
                <th className="list-th-common ">P/A/SL/CL/HD</th>
                <th className="list-th-common relative ">
                  <span
                    className="dropdown-table-btn flex justify-center items-center"
                    onClick={toggleDropdown}>
                    Type Staff{" "}
                    {isOpen ? (
                      <Icons.arrowclose size={24} />
                    ) : (
                      <Icons.arroopen size={24} />
                    )}
                  </span>
                  {isOpen && (
                    <ul className="dropdown-table-menu-head">
                      <li className="dropdown-table-menu-text">
                        Project Manager
                      </li>
                      <li className="dropdown-table-menu-text">Sr. Engineer</li>
                      <li className="dropdown-table-menu-text">Engineer</li>
                      <li className="dropdown-table-menu-text">
                        Site Incharge
                      </li>
                      <li className="dropdown-table-menu-text">Foreman</li>
                      <li className="dropdown-table-menu-text">Supercisor</li>
                    </ul>
                  )}
                </th>
                <th className="list-th-common">Company</th>
              </tr>
            </thead>
            <tbody>
              {[...Array(5)].map((_, rowIndex) => (
                <tr key={rowIndex}>
                  {[...Array(5)].map((_, colIndex) => (
                    <td key={colIndex} className="td-cash"></td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <AttendanceBelowTable />
    </>
  );
};

export default AttendanceReport;
