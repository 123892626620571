import React from 'react'
import { Icons } from '../../../../icons'

const AddNew = ({isOpen,onClose}) => {
    if(!isOpen){
        return null
    }
  return (
    <div className="add-main">
    <div className="add-popup">
      {/* Popup Header */}
      <div className="add-pop-header">
        <h2 className="add-heading">Add New</h2>
      <span onClick={onClose} className=" cursor-pointer"><Icons.cross size={20}/></span> 
      </div>
      <hr className="mt-5 mx-5"/>
      {/* Form */}
      <form className="p-6">
        {/* Row 1 */}
        <div className="add-grid-container">
          <div>
            <label className="add-lable">
              Name
            </label>
            <input
              type="text"
              className="add-input"
              placeholder="Enter Name"
            />
          </div>
          <div>
            <label className="add-lable">
              Date
            </label>
            <input
              type="date"
              className="add-input"
              placeholder=" "
            />
          </div>
        </div>

        {/* Row 2 */}
        <div className="add-grid-container">
          <div>
            <label className="add-lable">
             Start Date
            </label>
            <input
              type="date"
              className="add-input"
              placeholder=""
            />
          </div>
          <div>
            <label className="add-lable">
              End Date
            </label>
            <input
              type="date"
              className="add-input"
              placeholder=" "
            />
          </div>
        </div>

        {/* Row 3 */}
        <div className="add-grid-container">
          {/* <div>
            <label className="add-lable">
            Total Cost
            </label>
            <input
              type="number"
              className="add-input"
              placeholder=" "
            />
          </div> */}
        </div>

        {/* Buttons */}
        <div className="add-btn-container">
          <button
            type="button"
            onClick={onClose}
            className="in-add-cancel-btn"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="in-add-confirm-btn"
          >
            Confirm
          </button>
        </div>
      </form>
    </div>
  </div>
  )
}

export default AddNew