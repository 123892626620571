import React from "react";
import Safety from "./Safety";

const SafetyTraining = () => {
  return (
    <div className="">
      <div className="overflow-x-auto">
        <Safety />
        <table className="table-in-cashflow">
          <thead>
            <tr className="bg-[#78222E] text-white">
              <th className="list-th-common ">Sr.No</th>
              <th className="list-th-common ">Date</th>
              <th className="list-th-common ">Name of person</th>
              <th className="list-th-common ">Designation</th>
              <th className="list-th-common ">Company Name </th>
              <th className="list-th-common ">Signature</th>
              <th className="list-th-common ">Comments</th>
            </tr>
          </thead>
          <tbody>
            {[...Array(4)].map((_, rowIndex) => (
              <tr key={rowIndex}>
                {[...Array(7)].map((_, colIndex) => (
                  <td key={colIndex} className="td-cash "></td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SafetyTraining;
