import React from 'react'
import { Icons } from '../../../../icons'

const ProcurementTablesecond = () => {
    return (
        <div className="list-table-head">
            <table className="list-table">
                <thead className=''>
                    <tr className="list-table-container">
                        <th className="list-th">Access to User</th>
                        <th className="list-th">User Name</th>
                        <th className="list-th">View</th>
                        <th className="list-th">Modify</th>

                    </tr>
                </thead>
                <tbody>
                    {/* Example Row */}
                    <tr className="list-tr">
                        <td className="list-th"> <select className="select-div">
                            <option>Select</option>
                            <option>Tender</option>
                            <option>Approval</option>
                            <option>Option 3</option>
                        </select></td>
                        <td className="list-th">Komal</td>
                        <td className="list-th"><Icons.check size={30} className=' bg-green-500 text-white p-1 rounded-lg'/></td>
                        <td className="list-th"><Icons.check size={30} className=' bg-green-500 text-white p-1 rounded-lg '/></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ProcurementTablesecond