import React, { useState } from 'react';
import { Icons } from '../../../../icons';

const ContractTab = () => {


  return (
    <div className="">
      {/* Table */}
      <div className="list-table-head">
        <table className="list-table">
          <thead className=''>
            <tr className="list-table-container">
              <th className="list-th">Contract Number</th>
              <th className="list-th">Company Name</th>
              <th className="list-th">Package Name</th>
              <th className="list-th">Project Number</th>
              <th className="list-th">Action</th>
            </tr>
          </thead>
          <tbody>
            {/* Example Row */}
            <tr className="list-tr">
              <td className="list-th">Sample Project</td>
              <td className="list-th">12345</td>
              <td className="list-th">123 Main St, City</td>
              <td className="list-th">2500</td>
              <td className="list-th list-action">
               <Icons.edit size={24} />
                  <Icons.read size={24} />
                
              </td>
            </tr>
          </tbody>
        </table>
      </div>
          {/* Pagination */}
          <div className="pagi-container">
        {/* Previous Button */}
        <button
          className="pagi-prev-btn"
          aria-label="Previous"
        >
          <Icons.sliderarrowback />
        </button>

        {/* Pagination Dots */}
        <div className="pagi-dots">
          {[1, 2,'...',60].map((item) => (
            <button
              key={item}
              className={`sm:px-4 px-2 sm:py-2 ${item === 2 ? "bg-[#78222E] text-white" : "bg-gray-200 text-gray-600"
                }`}
            >
              {item}
            </button>
          ))}
        </div>

        {/* Next Button */}
        <button
          className="pagi-next-btn"
          aria-label="Next"
        >
          <Icons.sliderarrowgo />
        </button>
      </div>
    </div>
  );
};

export default ContractTab;
