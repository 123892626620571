import React, { useState } from 'react'
import { Icons } from '../../../../icons';
import IncurenceTable from './IncurenceTable'
const IncurenceHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  const projects = ["Project A", "Project B", "Project C", "Project D"];


  return (
    <>
       <div className="list-header">
          {/* Header */}
       <h1 className="list-heading">13 Compliances/Insurances</h1>
       <div className=' flex gap-4'>
         <div className="dropdown-relative">
           {/* Button */}
           <button
             className="dropdown-container-btn"
             onClick={() => setIsOpen(!isOpen)}
           >
             <span>Selet Check list</span>
             <span>
               {/* Replace with your Icons.arrowdropdown */}
               <svg
                 xmlns="http://www.w3.org/2000/svg"
                 className={`h-5 w-5 transform ${isOpen ? "rotate-180" : ""}`}
                 fill="none"
                 viewBox="0 0 24 24"
                 stroke="currentColor"
               >
                 <path
                   strokeLinecap="round"
                   strokeLinejoin="round"
                   strokeWidth={2}
                   d="M19 9l-7 7-7-7"
                 />
               </svg>
             </span>
           </button>
 
           {/* Dropdown Menu */}
           {isOpen && (
             <div className="dropdown-menu-container">
               <ul className="">
                 {projects.map((project, index) => (
                   <li
                     key={index}
                     onClick={() => {
                       setIsOpen(false);
                       alert(`Selected: ${project}`);
                     }}
                   >
                     {project}
                   </li>
                 ))}
               </ul>
             </div>
           )}
         </div>
         {/* <button className="list-add-btn">
           Add Required<Icons.add />
         </button>
         <button className="list-add-btn">
           Create New Check List<Icons.add />
         </button> */}
       </div>
     </div>
     <IncurenceTable />
     </>
  )
}

export default IncurenceHeader