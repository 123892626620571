import React from 'react'
import { Icons } from '../../../../icons'

const AddVendore = ({isOpen,onClose}) => {
    if(!isOpen){
        return null
    }
  return (
    <div> <div className="add-main">
    <div className="add-popup scrollbar-hide">
      {/* Popup Header */}
      <div className="add-pop-header">
        <h2 className="add-heading">Add Company</h2>
      <span onClick={onClose} className=" cursor-pointer"><Icons.cross size={20}/></span> 
      </div>
      <hr className="mt-5 mx-5"/>
      {/* Form */}
      <form className="p-6">
        {/* Row 1 */}
        <div className="add-grid-container">
          <div>
            <label className="add-lable">
              Company Name
            </label>
            <input
              type="text"
              className="add-input"
              placeholder="Enter project name"
            />
          </div>
          <div>
            <label className="add-lable">
              Profile
            </label>
            <input
              type="text"
              className="add-input"
              placeholder="Enter project number"
            />
          </div>
        </div>

        {/* Row 2 */}
        <div className="add-grid-container">
        <div>
                            <label className="add-lable">
                              Address line 1
                            </label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="Address line 1"
                            />
                        </div>
                        <div>
                            <label className="add-lable">
                            Address line 2
                            </label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="Address line 2"
                            />
                        </div>
        </div>
        <div className="add-grid-container">
        <div>
                            <label className="add-lable">
                               State
                            </label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="State"
                            />
                        </div>
                        <div>
                            <label className="add-lable">
                            City
                            </label>
                            <input
                                type="text"
                                className="add-input"
                                placeholder="City"
                            />
                        </div>
        </div>

        {/* Row 3 */}
        <div className="add-grid-container">
        <div>
            <label className="add-lable">
              Pincode
            </label>
            <input
              type="text"
              className="add-input"
              placeholder="Pincode"
            />
          </div>
          <div>
            <label className="add-lable">
              Area
            </label>
            <input
              type="text"
              className="add-input"
              placeholder="Enter area in sqft"
            />
          </div>
        <div>
            <label className="add-lable">
              Contract Number
            </label>
            <input
              type="text"
              className="add-input"
              placeholder="Contract Number"
            />
          </div>
          <div>
            <label className="add-lable">
              Upload Logo
            </label>
            <input
              type="file"
              className="add-input"
              accept="image/*"
            />
          </div>
        </div>

        {/* Buttons */}
        <div className="add-btn-container">
          <button
            type="button"
            onClick={onClose}
            className="in-add-cancel-btn"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="in-add-confirm-btn"
          >
            Confirm
          </button>
        </div>
      </form>
    </div>
  </div></div>
  )
}

export default AddVendore