import React from 'react'

const MaterialRequisition = () => {
  return (
    <div className="">
    <h1 className="list-heading mb-4 mt-4">Material Requisition</h1>
    <div className="overflow-x-auto">
        <table className="table-in-cashflow">
            <thead>
                <tr className="bg-[#78222E] text-white">
                    <th className="list-th-common ">Sr No.</th>
                    <th className="list-th-common ">Date</th>
                    <th className="list-th-common ">Description</th>
                    <th className="list-th-common ">Quantity</th>
                    <th className="list-th-common ">Required By</th>
                    <th className="list-th-common">
                        Date Required
                    </th>
                </tr>
            </thead>
            <tr className="bg-gray-100">
                            
                            <td className="tbody-td-cash"></td>
                            <td className="tbody-td-cash"></td>
                            <td className="tbody-td-cash"></td>
                            <td className="tbody-td-cash"></td>
                            <td colSpan="2" className="tbody-td-cash text-center">Name of person</td>
                            </tr>
            <tbody>
              
                {[...Array(4)].map((_, rowIndex) => (
                    <tr key={rowIndex}>
                        {[...Array(6)].map((_, colIndex) => (
                            <td
                                key={colIndex}
                                className="td-cash"
                            ></td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
</div>
  )
}

export default MaterialRequisition