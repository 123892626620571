import React from 'react'

const Footer = () => {
  return (
    <div className='footer-main'>
    <p className='footer-right '>Copyright.Conso Management Services Pvt. Ltd</p>
      <div className='footer-right'>
        <p>About</p>
        <p>Terms of usage</p>
      </div>
    </div>
  )
}

export default Footer