import React from 'react'
import { Icons } from '../../../../icons'

const AddDrawingModal = ({isOpen,onClose}) => {
    if(!isOpen){
        return null
    }
  return (
    <div className="add-main">
    <div className="add-popup scrollbar-hide">
        {/* Popup Header */}
        <div className="add-pop-header">
            <h2 className="add-heading">Add Drawning</h2>
            <span onClick={onClose} className=" cursor-pointer"><Icons.cross size={20} /></span>
        </div>

        {/* Form */}
        <form className="p-6">
            {/* Row 1 */}
            <div className="add-grid-container">
                <div>
                    <label className="add-lable">
                    Drawning No.
                    </label>
                    <input
                        type="text"
                        className="add-input"
                        placeholder="drawing number"
                    />
                </div>
                <div>
                    <label className="add-lable">
                    Drawning Name
                    </label>
                    <input
                        type="text"
                        className="add-input"
                        placeholder="drawning name"
                    />
                </div>
            </div>

            {/* Row 2 */}
            <div className="add-grid-container">
                <div>
                    <label className="add-lable">
                        Revision
                    </label>
                    <input
                        type="text"
                        className="add-input"
                        placeholder="revision"
                    />
                </div>
                <div>
                    <label className="add-lable">
                        Status
                    </label>
                    <select
                        className="add-input bg-white"
                        defaultValue=""
                    >
                        <option value="" disabled>
                            Select 
                        </option>
                        <option value="new-york">For Approval</option>
                        <option value="los-angeles">Good For construction</option>
                    </select>
                </div>

            </div>

            {/* Row 3 */}
               <div className="add-grid-container">
               <div>
                    <label className="add-lable">
                        Folder
                    </label>
                    <select
                        className="add-input bg-white"
                        defaultValue=""
                    >
                        <option value="" disabled>
                            Select 
                        </option>
                        <option value="new-york">structural</option>
                        <option value="los-angeles">Architectral
                        </option>
                     
                    </select>
                </div>
                <div>
                    <label className="add-lable">
                        Upload
                    </label>
                    <input
                        type="file"
                        className="add-input"
                        accept="image/*"
                    />
                </div>

            </div>
  


            {/* Buttons */}
            <div className="add-btn-container">
                <button
                    type="button"
                    onClick={onClose}
                    className="in-add-cancel-btn"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="in-add-confirm-btn"
                >
                    Confirm
                </button>
            </div>
        </form>
    </div>
</div>
  )
}

export default AddDrawingModal