import React from 'react';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

// Register required components
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const ManpowerChart = () => {
  const data = {
    labels: ['Monday', 'tuesday', 'wensday', 'thursday', 'friday', 'saturday', 'sunday'], // X-axis labels
    datasets: [
      {
        label: 'Plumber',
        data: [5600, 6000, 6400, 4000, 6000, 4000, 3000,4553,2223,5555],
        backgroundColor: '#370008', // Revenue bar color
        borderWidth: 1,
        borderRadius: 10, // Rounded top edges
        barThickness: 18, // Set specific bar width
        
      },
      {
        label: 'Electrician',  
        data: [4000, 5000, 4000, 6000, 7000, 5000, 4000,4553,2223,5555],
        backgroundColor: '#EDEDED', // Profit bar color
        borderWidth: 1,
        borderRadius: 10, // Rounded top edges
        barThickness: 18, // Set specific bar width
      },
      {
        label: 'Carpenter',  
        data: [3000, 4000, 5000, 3000, 4000, 5000, 3000,4553,2223,5555],
        backgroundColor: '#717171', // Profit bar color
        borderWidth: 1,
        borderRadius: 10, // Rounded top edges
        barThickness: 18, // Set specific bar widt
      },
      {
        label: 'Mason',  
        data: [5000, 3000, 4000, 3000, 2000, 3000, 4000,4553,2223,5555],
        backgroundColor: '#78222E', // Profit bar color
        borderWidth: 1,
        borderRadius: 10, // Rounded top edges
        barThickness: 18, // Set specific bar width
      },
      {
        label: 'Fitter',  
        data: [4000, 5000, 2300, 3000, 6000, 7000, 4000,4553,2223,5555],
        backgroundColor: '#D1D1D1', // Profit bar color
        borderWidth: 1,
        borderRadius: 10, // Rounded top edges
        barThickness: 18, // Set specific bar width
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      tooltip: {
        enabled: true, // Enable tooltips for interactivity
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false, // Disable horizontal grid lines
        },
      },
      x: {
        grid: {
          display: false, // Disable vertical grid lines
        },
      },
    },
  };
  
  
  return (
    <div  className='w-full mt-6 p-4 shadow-lg rounded-lg'> 
    <h1 className=' text-xl font-semibold px-6'>Manpower Histogram</h1>
      <Bar data={data} options={options} width={500}/>
    </div>
  );
};

export default ManpowerChart;
