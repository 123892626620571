import React, { useState } from 'react'
import { Icons } from '../../../icons';

const TableSecond = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    return (
        <div className="">
            <div className="overflow-x-auto">
                <h1 className="list-heading mb-6">Cashflow</h1>

                <table className="table-in-cashflow">
                    <thead>
                        <tr className="bg-[#78222E] text-white">
                        <th className="list-th-common relative">
                           Invetory Number
                            </th>
                            <th className="list-th-common ">Jan-25</th>
                            <th className="list-th-common ">Feb-25</th>
                            <th className="list-th-common ">Mar-25</th>
                            <th className="list-th-common ">Apr-25</th>
                            <th className="list-th-common ">May-25</th>
                        </tr>
                    </thead>
                    <tbody>
                        {[...Array(4)].map((_, rowIndex) => (
                            <tr key={rowIndex}>
                                {[...Array(6)].map((_, colIndex) => (
                                    <td
                                        key={colIndex}
                                        className="td-cash "
                                    >
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default TableSecond