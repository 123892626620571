import React, { useState } from "react";
import { Icons } from "../../../../icons";
import { Link } from "react-router-dom";

const FolderTab = () => {
  return (
    <div className="">
      {/* Table */}
      <div className="list-table-head">
        <table className="list-table">
          <thead className="">
            <tr className="list-table-container">
              <th className="list-th">Folder</th>
              <th className="list-th">Drawing No</th>
              <th className="list-th">Drawing Name</th>
              <th className="list-th">Revision</th>
              <td className="list-th">Transmittal </td>

              {/* <th className="list-th">Status</th> */}
              <th className="list-th">Download File</th>
            </tr>
          </thead>
          <tbody>
            {/* Example Row */}
            <tr className="list-tr">
              <td className="list-th">Architectral</td>
              <td className="list-th">123456</td>
              <td className="list-th">
                <Link to="/dashboard/drawing-name">
                  http://localhost:3000/dashboard/drawing-name
                </Link>
              </td>
              <td className="list-th">123456</td>
              <td className="list-th">Transmittal </td>
              {/* Transmittal */}
              {/* <td className="list-th"> <select className="select-div">
                <option>Select</option>
                <option>Tender</option>
                <option>Approval</option>
                <option>Option 3</option>
              </select></td> */}
              <td className="list-th">
                <Icons.attechfile size={20} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FolderTab;
