import React from 'react'
import ProcurementPlanHead from './ProcurementPlanHead'
import { Icons } from '../../../../icons'

const ProcurementSummery = () => {
    return (
        <>
            <ProcurementPlanHead />
            <div className="list-table-head overflow-x-auto">

                <table className="w-full text-center border-collapse ">
                    <thead className=''>
                        <tr className="list-table-container">
                            <th className="list-th">Item No.</th>
                            <th className="list-th">Package Name</th>
                            <th className="list-th">Ammount</th>
                            <th className="list-th">Cost/SQft</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* Example Row */}
                        <tr className="border-t text-gray-600 ">
                            <td className="list-th">1</td>
                            <td className="list-th">Name</td>
                            <td className="list-th">123</td>
                            <td className="list-th">20</td>
                        </tr>
                        {/* Example Row */}
                        <tr className="border-t text-gray-600 ">
                            <td className="list-th">1</td>
                            <td className="list-th">Name</td>
                            <td className="list-th">123</td>
                            <td className="list-th">20</td>
                        </tr>

                        {/* Example Row */}
                        <tr className="border-t text-gray-600 ">
                            <td className="list-th">1</td>
                            <td className="list-th">Name</td>
                            <td className="list-th">123</td>
                            <td className="list-th">20</td>
                        </tr>
                    </tbody>
                </table>

            </div>
            <div className='mt-8 flex flex-wrap md:justify-between justify-center items-center gap-2'>
                <div className='flex gap-3 flex-wrap justify-center items-center'>
                    <button className=' save-btn'>Revise Budget A</button>
                    <button className=' save-btn'>Revise Budget B</button>
                    <button className=' send-btn'>Save Budget </button>
                </div>
                <div className='flex gap-3 flex-wrap justify-center items-center'>
                    <button className=' ammount-btn'>Total</button>
                    <button className=' ammount-btn'>Freeze Budget </button>

                </div>
            </div>
            <div className='mt-8 space-y-2 flex flex-col md:justify-self-end justify-center items-center'>
                <button className=' save-btn flex gap-2'>Total Project Budget Save PDF <Icons.download size={20}/></button>
                <button className=' save-btn flex gap-2'>Revison A Budget Save PDF  <Icons.download size={20} /></button>

                <button className=' save-btn flex gap-2'>Revision B Budget Save PDF <Icons.download size={20}/> </button>
            </div>
        </>
    )
}

export default ProcurementSummery