import React from "react";

const Signup = () => {
  return (
    <div
      className="signup-main-img"
    >
      {/* Glassmorphic container */}
      <div className="signup-glassmorphics-container">
        <h2>
          Signup
        </h2>
        <form className="signup-form">
          {/* First Row: First Name & Last Name */}
          <div className="signup-form-row">
            {/* First Name */}
            <div>
              <label
                htmlFor="firstName"
                className="block text-sm  text-white mb-2"
              >
                First Name
              </label>
              <input
                type="text"
                id="firstName"
                placeholder="Enter your first name"
                className="signup-input"
              />
            </div>

            {/* Last Name */}
            <div>
              <label
                htmlFor="lastName"
                className="block text-sm  text-white mb-2"
              >
                Last Name
              </label>
              <input
                type="text"
                id="lastName"
                placeholder="Enter your last name"
                className="signup-input"
              />
            </div>
          </div>

          {/* Second Row: Email & Company Name */}
          <div className="signup-form-row">
            {/* Email */}
            <div>
              <label
                htmlFor="email"
                className="block text-sm  text-white mb-2"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                className="signup-input"
              />
            </div>

            {/* Company Name */}
            <div>
              <label
                htmlFor="company"
                className="block text-sm  text-white mb-2"
              >
                Company Name
              </label>
              <input
                type="text"
                id="company"
                placeholder="Enter your company name"
                className="signup-input"
              />
            </div>
          </div>

          {/* Third Row: Password & Confirm Password */}
          <div className="signup-form-row">
            {/* Password */}
            <div>
              <label
                htmlFor="password"
                className="signup-lable"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                placeholder="Enter your password"
                className="signup-input"
              />
            </div>

            {/* Confirm Password */}
            <div>
              <label
                htmlFor="confirmPassword"
                className="signup-lable"
              >
                Confirm Password
              </label>
              <input
                type="password"
                id="confirmPassword"
                placeholder="Re-enter your password"
                className="signup-input"
              />
            </div>
          </div>

          {/* Fourth Row: Referral Code */}
          <div>
            <label
              htmlFor="referralCode"
              className="signup-lable"
            >
              Referral Code (Optional)
            </label>
            <input
              type="text"
              id="referralCode"
              placeholder="Enter referral code"
              className="signup-input"
            />
          </div>

          {/* Submit Button */}
          <div className="signup-submit">
            <button
              type="submit"
            >
              Signup
            </button>
          </div>
        </form>
        <p className=' copy-right'>Copyright.Conso Management Services Pvt. Ltd</p>

      </div>
    </div>
  );
};

export default Signup;
