import React from 'react';

const Home = () => {
  return (
    <div className='home-main'
    >
      {/* Content goes here */}
      <div className="home-content">
        <h1>
          Construction of
          private houses
        </h1>
        <p>
          All types of work - form designing and laying the foundations to
          finishing and commissioning. Tight deadlines, reasonable prices,
          free shipping.
        </p>
        <div className="home-btn-main">
          <button>Get a quote</button>
          <hr/>
        </div>
      </div>
    </div>
  );
};

export default Home;
