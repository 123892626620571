import React from "react";

const PlanningTab = () => {
    return (
        <div className=" shadow-md">
            <div className="overflow-x-auto">
                <table className="table-in-cashflow">
                    <thead>
                        <tr className="bg-[#78222E] text-white">
                        <th className="list-th-common ">S. No.</th>

                            <th className="list-th-common ">Activity Name</th>
                            <th className="list-th-common ">Total quantity</th>
                            <th className="list-th-common ">Productivity rate per day</th>
                            <th className="list-th-common ">Duration</th>
                            {/* preceding activity */}
                            <th className="list-th-common ">Preceding activity</th>

                            <th className="list-th-common ">Start Date</th>
                            <th className="list-th-common">
                                Milestone Date
                            </th>
                            <th className="list-th-common">Freeze</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr className="bg-gray-100">
                            <td className="tbody-td-cash"></td>
                            <td className="tbody-td-cash"></td>
                            <td className="tbody-td-cash text-center">(Qty/Day)</td>
                            <td className="tbody-td-cash text-center">(Quantity/
                                Productivity)
                                C/D</td>
                            <td className="tbody-td-cash"></td>
                                                            <td className="tbody-td-cash"></td>

                            <td className="tbody-td-cash text-center">E+F</td>
                            <td className="tbody-td-cash text-center">Freeze
                                Milestone
                                Date</td>
                                <td className="tbody-td-cash"></td>


                        </tr>
                        {[...Array(10)].map((_, rowIndex) => (
                            <tr key={rowIndex}>
                                {[...Array(9)].map((_, colIndex) => (
                                    <td
                                        key={colIndex}
                                        className="td-cash"
                                    ></td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default PlanningTab;
